/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Typography from './Typography';

interface StyleProps {
  variant: 'contained' | 'outlined' | 'text';
}
const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: '8px',
    boxShadow: 'none',
    textDecoration: 'none',
    textTransform: 'none',
    color: (props: StyleProps) => (props.variant === 'contained'
      ? theme.palette.greyScale.superlight
      : theme.palette.primary.main),
    '&:hover': {
      boxShadow: 'none',

      backgroundColor: (props: StyleProps) => (props.variant === 'contained'
        ? theme.palette.primary.dark
        : theme.palette.primary.main),
      color: theme.palette.greyScale.superlight,
    },
    borderColor: theme.palette.primary.main,
    backgroundColor: (props: StyleProps) => (props.variant === 'contained'
      ? theme.palette.primary.main
      : theme.palette.greyScale.superlight),
  },
}));

interface Props extends ButtonProps {
  text: string;
  variant: 'contained' | 'outlined' | 'text';
}

const defaultProps = {};

const ButtonUI = ({ text, variant, ...props }: Props) => {
  const classes = useStyles({ variant });
  return (
    <>
      <Button className={classes.button} variant={variant} {...props}>
        <Typography variant="secondary2bold" color="inherit">
          {text}
        </Typography>
      </Button>
    </>
  );
};

ButtonUI.defaultProps = (defaultProps as unknown) as Partial<Props>;

export default ButtonUI;
