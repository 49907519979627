import {
  NotificationsResult,
  NewNotificationsResult,
  MarkNotificationsOldResult,
} from 'typings/notifications';
import api from '../../../api';

export default function useNotificationsApi() {
  async function fetchNotifications(
    token: string,
    cursor?: string,
  ): Promise<NotificationsResult> {
    if (cursor) {
      const response = await api(token).get(cursor);
      return response.data;
    }
    const response = await api(token).get(
      '/notifications/notifications/',
    );
    return response.data;
  }
  async function fetchNewNotifications(
    token: string,
  ): Promise<NewNotificationsResult> {
    const response = await api(token).get(
      '/notifications/notifications/new_items/',
    );
    return response.data;
  }

  async function markNotificationAsRead(
    token: string,
    notificationId: number,
  ): Promise<Notification | null> {
    const response = await api(token).post(
      `/notifications/notifications/${notificationId}/mark_object_as_read/`,
      { },
    );
    return response.data;
  }

  async function markNotificationAsOld(
    token: string,
    notificationId: number,
  ): Promise<Notification | null> {
    const response = await api(token).post(
      `/notifications/notifications/${notificationId}/mark_object_as_old/`,
      { },
    );
    return response.data;
  }

  async function markAllNotificationAsOld(
    token: string,
  ): Promise<MarkNotificationsOldResult> {
    await api(token).post(
      '/notifications/notifications/mark_all_objects_as_old/',
      { },
    );
    return { new_count: 0 };
  }

  async function markAllNotificationAsRead(
    token: string,
  ): Promise<null> {
    await api(token).post(
      '/notifications/notifications/mark_all_objects_as_read/',
      { },
    );
    return null;
  }

  return {
    fetchNewNotifications,
    fetchNotifications,
    markNotificationAsRead,
    markNotificationAsOld,
    markAllNotificationAsOld,
    markAllNotificationAsRead,
  };
}
