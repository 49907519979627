import React from 'react';

interface Props {
  variant?: 'dark' | 'light'
}

const Background = ({ variant = 'light', mirrored = false }: Props) => (
  <svg
    width="1111"
    height="569"
    // width="100%"
    // height="100%"
    // preserveAspectRatio="none"
    viewBox="0 0 1111 569"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-500, 100)">
      <path d="M577 1L1110 534" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M527 2L1078 553" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M481 7L1027 553" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M430 7L977 554" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M374 2L929 557" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M328 7L881 560" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M283 13L833 563" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M232 13L784 565" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M188 20L687 519" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M138 21L657 540" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M93 27L606 540" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M44 29L568 553" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M14 50L530 566" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path d="M1 88L481 568" stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'} strokeMiterlimit="10" />
      <path
        d="M585.56 43.1H569.06V59.6H585.56V43.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M615.06 72.6H598.56V89.1H615.06V72.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M673.56 131.1H657.06V147.6H673.56V131.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M761.56 219.1H745.06V235.6H761.56V219.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M849.561 307.1H833.061V323.6H849.561V307.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1025.56 483.1H1009.06V499.6H1025.56V483.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M409.56 21.1H393.06V37.6H409.56V21.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M497.56 109.1H481.06V125.6H497.56V109.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M615.06 226.6H598.56V243.1H615.06V226.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M792.06 405.6H775.56V422.1H792.06V405.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M761.56 373.1H745.06V389.6H761.56V373.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M849.561 461.1H833.061V477.6H849.561V461.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M306.56 73.1H290.06V89.6H306.56V73.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M373.56 139.1H357.06V155.6H373.56V139.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M409.56 175.1H393.06V191.6H409.56V175.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M497.56 263.1H481.06V279.6H497.56V263.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M585.56 351.1H569.06V367.6H585.56V351.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M673.56 439.1H657.06V455.6H673.56V439.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M753.56 517.1H737.06V533.6H753.56V517.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M156.06 74.6H139.56V91.1H156.06V74.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M232.06 150.6H215.56V167.1H232.06V150.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M373.56 291.1H357.06V307.6H373.56V291.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M497.56 415.1H481.06V431.6H497.56V415.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M156.06 226.6H139.56V243.1H156.06V226.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M232.06 302.6H215.56V319.1H232.06V302.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M306.56 377.1H290.06V393.6H306.56V377.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M373.56 443.1H357.06V459.6H373.56V443.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M409.56 479.1H393.06V495.6H409.56V479.1Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M605.06 12.6H588.56V29.1H605.06V12.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M729.06 136.6H712.56V153.1H729.06V136.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M853.061 260.6H836.561V277.1H853.061V260.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M903.061 310.6H886.561V327.1H903.061V310.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M977.061 384.6H960.561V401.1H977.061V384.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1101.06 508.6H1084.56V525.1H1101.06V508.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M481.06 41.6H464.56V58.1H481.06V41.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M546.06 106.6H529.56V123.1H546.06V106.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M605.06 165.6H588.56V182.1H605.06V165.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M729.06 289.6H712.56V306.1H729.06V289.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M853.061 413.6H836.561V430.1H853.061V413.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M903.061 463.6H886.561V480.1H903.061V463.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M357.06 70.6H340.56V87.1H357.06V70.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M481.06 194.6H464.56V211.1H481.06V194.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M546.06 259.6H529.56V276.1H546.06V259.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M605.06 318.6H588.56V335.1H605.06V318.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M729.06 442.6H712.56V459.1H729.06V442.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M233.06 99.6H216.56V116.1H233.06V99.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M274.06 140.6H257.56V157.1H274.06V140.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M357.06 223.6H340.56V240.1H357.06V223.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M481.06 347.6H464.56V364.1H481.06V347.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M546.06 412.6H529.56V429.1H546.06V412.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M605.06 471.6H588.56V488.1H605.06V471.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M109.06 128.6H92.5596V145.1H109.06V128.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M233.06 252.6H216.56V269.1H233.06V252.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M274.06 293.6H257.56V310.1H274.06V293.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M357.06 376.6H340.56V393.1H357.06V376.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M481.06 500.6H464.56V517.1H481.06V500.6Z"
        stroke={variant === 'light' ? '#FAFAFA' : '#3f51b5'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M792.06 251.6H775.56V268.1H792.06V251.6Z" fill="#FFC107" />
      <path d="M937.561 395.1H921.061V411.6H937.561V395.1Z" fill="#FFC107" />
      <path d="M585.56 197.1H569.06V213.6H585.56V197.1Z" fill="#FFC107" />
      <path d="M673.56 285.1H657.06V301.6H673.56V285.1Z" fill="#FFC107" />
      <path
        d="M615.06 380.6H598.56V397.1H615.06V380.6Z"
        fill="#FFC107"
      />
      <path d="M306.56 225.1H290.06V241.6H306.56V225.1Z" fill="#FFC107" />
      <path d="M409.56 327.1H393.06V343.6H409.56V327.1Z" fill="#FFC107" />
      <path d="M585.56 503.1H569.06V519.6H585.56V503.1Z" fill="#FFC107" />
      <path d="M78.0596 148.6H61.5596V165.1H78.0596V148.6Z" fill="#FFC107" />
    </g>
  </svg>
);

export { Background };
