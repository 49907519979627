/* eslint-disable no-param-reassign */
// param reassign is safe because it's redux-toolkit
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import marketsApi from './api';
import marketsInitialState from './initialState';

const api = marketsApi();

export const getOfferings = createAsyncThunk(
  'markets/getOfferings',
  async ({ page = 1, pageSize }: {page:number, pageSize?:number}, { getState }) => {
    const { auth, markets } = getState();
    return api.getOfferings(
      auth.token || '',
      { ...markets.newFilters.data, searchTerm: markets.newFilters.searchTerm as string },
      markets.newFilters.sortBy, page, pageSize,

    );
  },
);

export const getOffering = createAsyncThunk(
  'markets/getOffering',
  async ({ slug }:{slug:string}, { getState }) => {
    const { auth } = getState();
    return api.getOffering(slug, auth.token || '');
  },
);

export const toggleAddWatchlist = createAsyncThunk(
  'markets/toggleAddWatchlist',
  async ({ slug }: {slug:string}, { getState }) => {
    const { auth } = getState();
    return api.toggleAddWatchlist(auth.token || '', slug);
  },
);

export const getWatchlist = createAsyncThunk(
  'markets/getWatchlist',
  async ({ pageSize, page }:{pageSize:number, page?:number}, { getState }) => {
    const { auth } = getState();
    return api.getWatchlist(auth.token || '', pageSize, page);
  },
);

// fetch filter helpers
export const fetchFilterHelpers = createAsyncThunk(
  'community/fetchFilterHelpers',
  async (v:void, { getState }) => {
    const { auth } = getState();
    return api.getFilterHelpers(auth.token || '');
  },
);

// fetch filter values
export const fetchFiltersData = createAsyncThunk(
  'markets/filter',
  async (v:void, { getState }) => {
    const { auth } = getState();
    return api.fetchFiltersData(auth.token || '');
  },
);

// get data banner
export const getDataBanner = createAsyncThunk(
  '/markets/filter-limits/',
  async (v:void, { getState }) => {
    const { auth } = getState();
    return api.getDataBanner(auth.token || '');
  },
);

// get special offerings
export const getSpecialOfferings = createAsyncThunk(
  '/markets/special-offerings/',
  async (v:void, { getState }) => {
    const { auth } = getState();
    return api.getSpecialOfferings(auth.token || '');
  },
);

const addCheckFalse = (inputObject:any[]) => inputObject.map(
  (item) => {
    if (item.checked) {
      return item;
    }
    return { ...item, checked: false };
  },
);

const markets = createSlice({
  name: 'markets',
  initialState: marketsInitialState,
  reducers: {
    reset: () => marketsInitialState,
    toggleCheckBox: (state, { payload }:PayloadAction<{
      id: string | number;
      type: 'platforms' | 'sectors' | 'specialInterest' | 'status' | 'securityType' | 'stage';
    }>) => {
      const toChange = state.newFilters.data[payload.type].find((x) => x.id === payload.id);
      if (toChange) {
        toChange.checked = !toChange.checked;
      }
    },
    /* toggleToggle: (state, {payload})=>{
      state.newFilters.data.[payload]=!state.newFilters.data.[payload];
    }, */
    togglePreviousRounds: (state) => {
      state.newFilters.data.previousRounds = !state.newFilters.data.previousRounds;
    },
    toggleSerialFundraisers: (state) => {
      state.newFilters.data.serialFundraisers = !state.newFilters.data.serialFundraisers;
    },
    toggleAcredittedInvestor: (state) => {
      state.newFilters.data.acredittedInvestor = !state.newFilters.data.acredittedInvestor;
    },

    changeSliderValue: (state,
      { payload }: PayloadAction<{values: [number, number], filterToChange: 'target' | 'minimumTicket' | 'valuationCap' | 'numberOfInvestors' | 'teamSize'}>) => {
      const { values: [minSelected, maxSelected], filterToChange } = payload;
      const changeElement = state.newFilters.data[filterToChange];
      if (minSelected < maxSelected && changeElement
        // this line basically removes micro-updates (aka a filter is technically
        // applied even if you're almost at the min)
        && (((Math.abs(minSelected - changeElement.min_selected)
        / changeElement.min_selected) > 0.1) || ((Math.abs(maxSelected - changeElement.max_selected)
        / changeElement.max_selected) > 0.1))) {
        changeElement.min_selected = Math.round(minSelected);
        changeElement.max_selected = Math.round(maxSelected);
      }
    },

    changeSelectedDate: (state, { payload }) => {
      state.newFilters.data.startDate.selected = payload || null;
    },

    clearAll: (state) => {
      state.newFilters.data = state.newFilters.initialValues;
    },
    clearSelectedPlatform: (state) => {
      state.newFilters.data.platforms = state.newFilters.initialValues.platforms;
    },
    clearSelectedSector: (state) => {
      state.newFilters.data.sectors = state.newFilters.initialValues.sectors;
    },

    changeNumberAppliedFilters: (state, { payload }) => {
      state.newFilters.appliedFilters = payload;
    },
    changeSearchTermValue: (state, { payload }) => {
      state.newFilters.searchTerm = payload;
    },
    changeResultsView: (state, { payload }) => {
      state.newFilters.selectedType = payload;
    },
    updatePage: (state, { payload }) => {
      state.newFilters.page = payload;
    },
    toggleSortBy: (state, { payload }) => {
      state.newFilters.sortBy = payload;
    },
    applyFiltersFromQueryParams: (state, { payload }) => {
      // platforms
      state.newFilters.data.platforms.filter(
        (x) => payload.platforms.includes(x.id.toString()),
      ).forEach((x) => { x.checked = true; });

      // sectors
      state.newFilters.data.sectors.filter(
        (x) => payload.sectors.includes(x.id.toString()),
      ).forEach((x) => { x.checked = true; });

      // special interests
      state.newFilters.data.specialInterest.filter(
        (x) => payload.specialInterest.includes(x.id.toString()),
      ).forEach((x) => { x.checked = true; });

      // status
      state.newFilters.data.status.filter(
        (x) => payload.status.includes(x.id.toString()),
      ).forEach((x) => { x.checked = true; });

      // funding target
      if (state.newFilters.data.target) {
        if (Math.min(...payload.target) > state.newFilters.data.target.min
        && Math.min(...payload.target) < state.newFilters.data.target.max
        ) {
          state.newFilters.data.target.min_selected = Math.min(...payload.target);
        }
        if (Math.max(...payload.target) > state.newFilters.data.target.min
        && Math.max(...payload.target) < state.newFilters.data.target.max) {
          state.newFilters.data.target.max_selected = Math.max(...payload.target);
        }
      }
      // min ticket
      if (state.newFilters.data.minimumTicket) {
        if (Math.min(...payload.minimumTicket) > state.newFilters.data.minimumTicket.min
        && Math.min(...payload.minimumTicket) < state.newFilters.data.minimumTicket.max) {
          state.newFilters.data.minimumTicket.min_selected = Math.min(...payload.minimumTicket);
        }
        if (Math.max(...payload.minimumTicket) > state.newFilters.data.minimumTicket.min
        && Math.max(...payload.minimumTicket) < state.newFilters.data.minimumTicket.max) {
          state.newFilters.data.minimumTicket.max_selected = Math.max(...payload.minimumTicket);
        }
      }

      // valuation cap
      if (state.newFilters.data.valuationCap) {
        if (Math.min(...payload.valuationCap) > state.newFilters.data.valuationCap.min
        && Math.min(...payload.valuationCap) < state.newFilters.data.valuationCap.max) {
          state.newFilters.data.valuationCap.min_selected = Math.min(...payload.valuationCap);
        }
        if (Math.max(...payload.valuationCap) > state.newFilters.data.valuationCap.min
        && Math.max(...payload.valuationCap) < state.newFilters.data.valuationCap.max) {
          state.newFilters.data.valuationCap.max_selected = Math.max(...payload.valuationCap);
        }
      }

      // number of investors
      if (state.newFilters.data.numberOfInvestors) {
        if (Math.min(...payload.numberOfInvestors) > state.newFilters.data.numberOfInvestors.min
        && Math.min(...payload.numberOfInvestors) < state.newFilters.data.numberOfInvestors.max) {
          // eslint-disable-next-line max-len
          state.newFilters.data.numberOfInvestors.min_selected = Math.min(...payload.numberOfInvestors);
        }
        if (Math.max(...payload.numberOfInvestors) > state.newFilters.data.numberOfInvestors.min
        && Math.max(...payload.target) < state.newFilters.data.numberOfInvestors.max) {
          // eslint-disable-next-line max-len
          state.newFilters.data.numberOfInvestors.max_selected = Math.max(...payload.numberOfInvestors);
        }
      }

      // team size
      if (state.newFilters.data.teamSize) {
        if (Math.min(...payload.teamSize) > state.newFilters.data.teamSize.min
        && Math.min(...payload.teamSize) < state.newFilters.data.teamSize.max) {
          state.newFilters.data.teamSize.min_selected = Math.min(...payload.teamSize);
        }
        if (Math.max(...payload.teamSize) > state.newFilters.data.teamSize.min
        && Math.max(...payload.teamSize) < state.newFilters.data.teamSize.max) {
          state.newFilters.data.teamSize.max_selected = Math.max(...payload.teamSize);
        }
      }

      // prev rounds
      if (state.newFilters.data.previousRounds) {
        state.newFilters.data.previousRounds = payload.previousRounds;

      // successful
      } if (state.newFilters.data.successful) {
        state.newFilters.data.successful = payload.succesful;
      } if (state.newFilters.data.acredittedInvestor) {
        state.newFilters.data.acredittedInvestor = payload.acredittedInvestor;
      }
      state.newFilters.hasAppliedFiltersFromQueryParams = true;
    },
  },
  extraReducers: (builder) => {
    builder
    // fetch offerings
      .addCase(getOfferings.pending, (state) => {
        state.offerings.isLoading = true;
      })
      .addCase(getOfferings.fulfilled, (state, action) => {
        state.offerings.isLoading = false;
        state.offerings.data = action.payload;
      })
      .addCase(getOfferings.rejected, (state, action) => {
        state.offerings.isLoading = false;
        const { message } = action.error;
        state.offerings.error = message || null;
      })
      // fetch offering
      .addCase(getOffering.pending, (state, action) => {
        state.offering.isLoading = true;
        state.offering.selectedId = action.meta.arg.slug;
      })
      .addCase(getOffering.fulfilled, (state, action) => {
        state.offering.isLoading = false;
        state.offering.data = action.payload;
      })
      // toggleAddWatchlist
      .addCase(toggleAddWatchlist.pending, (state, action) => {
        // results
        const offeringToToggle = state.offerings.data.results.find(
          (x) => x.slug === action.meta.arg.slug,
        );
        if (offeringToToggle) {
          offeringToToggle.has_user_subscribed = !offeringToToggle.has_user_subscribed;
        }

        // markets special offerings sections
        // trending
        const toToggleTrending = state.specialOfferings.data.trending.find(
          (x) => x.slug === action.meta.arg.slug,
        );
        if (toToggleTrending) {
          toToggleTrending.has_user_subscribed = !toToggleTrending.has_user_subscribed;
        }
        // ending soon
        const toToggleEndingSoon = state.specialOfferings.data.ending_soon.find(
          (x) => x.slug === action.meta.arg.slug,
        );
        if (toToggleEndingSoon) {
          toToggleEndingSoon.has_user_subscribed = !toToggleEndingSoon.has_user_subscribed;
        }
        // new
        const toToggleNew = state.specialOfferings.data.new.find(
          (x) => x.slug === action.meta.arg.slug,
        );
        if (toToggleNew) {
          toToggleNew.has_user_subscribed = !toToggleNew.has_user_subscribed;
        }

        // detail view
        if (state.offering.data?.slug === action.meta.arg.slug) {
          state.offering.data.has_user_subscribed = !state.offering.data.has_user_subscribed;
          state.offering.data.subscribers += state.offering.data.has_user_subscribed ? 1 : -1;
        }
        state.watchlist.data.results = state.watchlist.data.results.filter(
          (x) => x.slug !== action.meta.arg.slug,
        );
      })
      .addCase(toggleAddWatchlist.rejected, (state, action) => {
        // results
        const offeringToToggle = state.offerings.data.results.find(
          (x) => x.slug === action.meta.arg.slug,
        );
        if (offeringToToggle) {
          offeringToToggle.has_user_subscribed = !offeringToToggle.has_user_subscribed;
        }

        // markets special offerings sections
        // trending
        const toToggleTrending = state.specialOfferings.data.trending.find(
          (x) => x.slug === action.meta.arg.slug,
        );
        if (toToggleTrending) {
          toToggleTrending.has_user_subscribed = !toToggleTrending.has_user_subscribed;
        }
        // ending soon
        const toToggleEndingSoon = state.specialOfferings.data.ending_soon.find(
          (x) => x.slug === action.meta.arg.slug,
        );
        if (toToggleEndingSoon) {
          toToggleEndingSoon.has_user_subscribed = !toToggleEndingSoon.has_user_subscribed;
        }
        // new
        const toToggleNew = state.specialOfferings.data.new.find(
          (x) => x.slug === action.meta.arg.slug,
        );
        if (toToggleNew) {
          toToggleNew.has_user_subscribed = !toToggleNew.has_user_subscribed;
        }

        // detail view
        if (state.offering.data?.slug === action.meta.arg.slug) {
          state.offering.data.has_user_subscribed = !state.offering.data.has_user_subscribed;
          state.offering.data.subscribers += state.offering.data.has_user_subscribed ? 1 : -1;
        }
      })

      // showWatchlist
      .addCase(getWatchlist.pending, (state) => {
        state.watchlist.isLoading = true;
      })
      .addCase(getWatchlist.fulfilled, (state, action) => {
        state.watchlist.isLoading = false;
        state.watchlist.data = action.payload;
      })

      // get special offerings
      .addCase(getSpecialOfferings.pending, (state) => {
        state.specialOfferings.isLoading = true;
      })
      .addCase(getSpecialOfferings.fulfilled, (state, action) => {
        state.specialOfferings.isLoading = false;
        state.specialOfferings.data = action.payload;
      })
      // get banner
      .addCase(getDataBanner.pending, (state) => {
        state.dataBanner.isLoading = true;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.dataBanner.isLoading = false;
        state.dataBanner.data = action.payload;
      })

      // fetch filtersData
      .addCase(fetchFiltersData.pending, (state) => {
        state.newFilters.isLoading = true;
        state.newFilters.error = null;
      })
      .addCase(fetchFiltersData.fulfilled, (state, action) => {
        state.newFilters.isLoading = false;
        // cada elemento de los filtros(platform, status...)
        // necesita una funcion para meterlos en el state
        const filters = state.newFilters.data;
        filters.platforms = addCheckFalse(action.payload.platforms);
        filters.sectors = addCheckFalse(action.payload.sectors);
        // filters.stage = addCheckFalse(action.payload.stage);
        filters.specialInterest = addCheckFalse(action.payload.specialInterest);

        filters.target = {
          min: action.payload.target.min,
          max: action.payload.target.max,
          min_selected: action.payload.target.min,
          max_selected: action.payload.target.max,
        };
        filters.minimumTicket = {
          min: action.payload.minimumTicket.min,
          max: action.payload.minimumTicket.max,
          min_selected: action.payload.minimumTicket.min,
          max_selected: action.payload.minimumTicket.max,
        };
        filters.valuationCap = {
          min: action.payload.valuationCap.min,
          max: action.payload.valuationCap.max,
          min_selected: action.payload.valuationCap.min,
          max_selected: action.payload.valuationCap.max,
        };
        filters.numberOfInvestors = {
          min: action.payload.numberOfInvestors.min,
          max: action.payload.numberOfInvestors.max,
          min_selected: action.payload.numberOfInvestors.min,
          max_selected: action.payload.numberOfInvestors.max,
        };
        filters.teamSize = {
          min: action.payload.teamSize.min,
          max: action.payload.teamSize.max,
          min_selected: action.payload.teamSize.min,
          max_selected: action.payload.teamSize.max,
        };

        state.newFilters.data = filters;
        state.newFilters.initialValues = filters;

        state.newFilters.hasFetchedFilterLimits = true;
      })
      .addCase(fetchFiltersData.rejected, (state, action) => {
        state.newFilters.isLoading = false;
        state.newFilters.error = action.error.message || null;
      });
  },
});

export { markets };

export default markets.reducer;

export const {
  reset,
  toggleCheckBox,
  // toggleToggle,
  clearAll,
  toggleSerialFundraisers,
  toggleAcredittedInvestor,
  changeSliderValue,
  changeNumberAppliedFilters,
  changeSearchTermValue,
  changeSelectedDate,
  clearSelectedPlatform,
  clearSelectedSector,
  changeResultsView,
  applyFiltersFromQueryParams,
  updatePage,
  toggleSortBy,
} = markets.actions;
