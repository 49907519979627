import qs from 'qs';
import { MarketsState, NewFilters } from 'typings/markets/state';
import {
  GetWatchlistPromise,
  OfferingListResultType,
  OfferingType,
  ToggleAddWatchlistPromise,
  FilterHelpers,
  BannerResult,
  SpecialOfferings,
} from 'typings/markets/api.d';
import api from '../../../api';

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface SearchTermProps {
  searchTerm: string | null
}

export default function marketsApi() {
  async function getOfferings(
    token: string,
    filters: MarketsState['newFilters']['data'] & SearchTermProps,
    order_by: string,
    page?:number,
    page_size?:number,
  ):Promise<OfferingListResultType> {
    const parameters = {
      company_name__icontains: filters.searchTerm || null,
      security_type__in: filters.securityType.filter(
        (x) => (x.checked === true),
      ).map((x) => (x.id)),
      status__in: filters.status.filter(
        (x) => (x.checked === true),
      ).map((x) => (x.id)),
      platform__in: filters.platforms.filter(
        (x) => (x.checked === true),
      ).map((x) => (x.id)),
      industry_category: filters.sectors.filter(
        (x) => (x.checked === true),
      ).map((x) => (x.id)),
      special_interest_tags: filters.specialInterest.filter(
        (x) => (x.checked === true),
      ).map((x) => (x.id)),
      investors_count__lte: filters.numberOfInvestors?.max_selected,
      investors_count__gte: filters.numberOfInvestors?.min_selected,
      number_of_employees__lte: filters.teamSize?.max_selected,
      number_of_employees__gte: filters.teamSize?.min_selected,
      funding_goal_min__lte: filters.target?.max_selected,
      funding_goal_min__gte: filters.target?.min_selected,
      ticket_amount_minimum__lte: filters.minimumTicket?.max_selected,
      ticket_amount_minimum__gte: filters.minimumTicket?.min_selected,
      start_date: filters.startDate.selected,
      page,
      page_size,
      order_by,
    };
    const response = await api(token).get('/markets/offerings/', {
      params: Object.fromEntries(Object.entries(parameters).filter(([_unused, v]) => v != null)),
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return response.data;
  }

  async function getOffering(
    slug: string,
    token: string,
  ): Promise<OfferingType | null> {
    const response = await api(token).get(`/markets/offerings/${slug}/`);
    return response.data;
  }

  async function toggleAddWatchlist(
    token:string,
    slug: string,
  ):Promise<ToggleAddWatchlistPromise> {
    const response = await api(token).post(`/markets/offerings/${slug}/toggle_watchlist/`);
    return response.data;
  }

  async function getWatchlist(
    token:string,
    page_size:number,
    page?:number,
  ):Promise<GetWatchlistPromise> {
    const response = await api(token).get('/markets/offerings/get_watchlist/', {
      params: {
        page_size,
        page,
      },
    });
    return response.data;
  }
  async function getFilterHelpers(
    token:string,
  ): Promise<FilterHelpers[]> {
    const response = await api(token).get('/markets/platforms/');
    return response.data;
  }

  async function fetchFiltersData(
    token: string,
  ): Promise<NewFilters> {
    const response = await api(token).get('/markets/filter-limits/');
    return response.data;
  }

  async function getDataBanner(token: string): Promise<BannerResult[]> {
    const response = await api(token).get('/markets/banner-items/');
    return response.data;
  }

  async function getSpecialOfferings(token: string):Promise<SpecialOfferings> {
    const response = await api(token).get('/markets/special-offerings/');
    return response.data;
  }

  return {
    getOfferings,
    getOffering,
    toggleAddWatchlist,
    getWatchlist,
    getFilterHelpers,
    fetchFiltersData,
    getDataBanner,
    getSpecialOfferings,
  };
}
