/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import '../style.css';
import App, { AppContext } from 'next/app';
import Head from 'next/head';
import type { AppProps /* , AppContext */ } from 'next/app';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { appWithTranslation } from 'next-i18next';
import { Provider, useDispatch, useSelector } from 'react-redux';
// import NProgress from 'nprogress';
// import Router from 'next/router';
// import 'nprogress/nprogress.css';

import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import {
  BlogPost, GlobalProps, Metadata, Section,
} from 'typings/pages';
import { NextPageContext } from 'next';
import store from '@/store/store';
import resetStore from '@/store/reset';
import { RootState } from '@/store/rootReducer';
import Notifier from '@/components/ui-components/Notifier';
import Cookies from '@/components/cookies/Cookies';

import CookieConfig from '@/components/cookies/CookieConfig';
import ErrorFallback from '@/components/layout/ErrorFallback';
import getStrapiMedia from '@/features/pages/utils/media';
import theme from '../src/theme';
import global from '../.global.json';
// Router.events.on('routeChangeStart', () => {
//   NProgress.start();
// });
// Router.events.on('routeChangeComplete', () => NProgress.done());
// Router.events.on('routeChangeError', () => NProgress.done());

interface ErrorBoundaryAppProps extends AppProps {

}
function ErrorBoundaryWrapper({ Component, pageProps }: ErrorBoundaryAppProps) {
  const dispatch = useDispatch();
  const { cookieConfigOpen } = useSelector((state: RootState) => state.globals);
  const router = useRouter();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => dispatch(resetStore())}
    >

      <Component {...pageProps} {...{ global }} key={router.asPath} />
      <CookieConfig open={cookieConfigOpen} />
    </ErrorBoundary>
  );
}

interface MyAppProps extends AppProps {
  pageProps: {
    global: GlobalProps,
    metadata: Metadata,
    sections: Section[]
    featuredBlogEntries: BlogPost[]
  }
}

const MyApp:React.FC<AppProps> = (props: MyAppProps) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  // Prevent Next bug when it tries to render the [[...slug]] route
  const router = useRouter();
  if (router.asPath === '/[[...slug]]') {
    return null;
  }

  // Extract the data we need
  if (global == null) {
    return null;
  }
  const { metadata } = global;

  if (!process.browser) {
    return (
      <>
        <Head>
          <title>Wixdom</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="theme-color" content="#ffffff" />
          <link rel="shortcut icon" href={getStrapiMedia(global.favicon.url)} />
        </Head>
        {/* Global site metadata */}
        <DefaultSeo
          titleTemplate={`%s | ${global.metaTitleSuffix}`}
          title="Wixdom"
          description={metadata.metaDescription}
          openGraph={{
            images: Object.values(metadata.shareImage?.formats || []).map((image) => (image ? {
              url: getStrapiMedia(image.url),
              width: image.width,
              height: image.height,
            } : { url: '', width: 0, height: 0 })),
          }}
          twitter={{
            cardType: metadata.twitterCardType,
            handle: metadata.twitterUsername || '',
          }}
        />
        <CookiesProvider>
          {/* <I18nextProvider i18n={i18n}> */}
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <Notifier />
                <CssBaseline />
                {/* <Cookies> */}
                <ErrorBoundaryWrapper {...props} />
                {/* </Cookies> */}
              </SnackbarProvider>
            </ThemeProvider>
          </Provider>
          {/* </I18nextProvider> */}
        </CookiesProvider>
      </>
    );
  }

  return (
    <>
      <Head>
        <title>Wixdom</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="shortcut icon" href={getStrapiMedia(global.favicon.url)} />
      </Head>
      {/* Global site metadata */}
      <DefaultSeo
        titleTemplate={`%s | ${global.metaTitleSuffix}`}
        title="Wixdom"
        description={metadata.metaDescription}
        openGraph={{
          images: Object.values(metadata.shareImage?.formats || []).map((image) => (image ? {
            url: getStrapiMedia(image.url),
            width: image.width,
            height: image.height,
          } : { url: '', width: 0, height: 0 })),
        }}
        twitter={{
          cardType: metadata.twitterCardType,
          handle: metadata.twitterUsername || '',
        }}
      />
      <CookiesProvider>
        {/* <I18nextProvider i18n={i18n}> */}
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <Notifier />
              <CssBaseline />
              <Cookies>
                <ErrorBoundaryWrapper {...props} />
              </Cookies>
            </SnackbarProvider>
          </ThemeProvider>
        </Provider>
        {/* </I18nextProvider> */}
      </CookiesProvider>
    </>
  );
};

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So [[...slug]] pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949

// @ts-ignore
// MyApp.getInitialProps = async (ctx:AppContext & NextPageContext) => {
//   // Calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(ctx);
//   // Fetch global site settings from Strapi
//   // const global = await getGlobalData();
//   // Pass the data to our page via props
//   return {
//     ...appProps,
//     // pageProps: { global, path: ctx.pathname },
//   };
// };

export default appWithTranslation(MyApp);
