import {
  createTheme,
  // PaletteColorOptions,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import { Color } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
  interface CustomColor extends Color {
    superdark?: string;
    superlight?: string;
    extralight?: string;
  }
  interface PaletteColor extends Partial<CustomColor> {}

  interface Palette {
    primary: PaletteColor;
    secondary: PaletteColor;
    greyScale: PaletteColor;
    positive: PaletteColor;
    negative: PaletteColor;
    medium: PaletteColor;
    information: PaletteColor;
    equity: PaletteColor;
    safe: PaletteColor;
    convertible: PaletteColor;
    debt: PaletteColor;
    revenue: PaletteColor;
  }

  interface PaletteOptions {
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    greyScale: PaletteColorOptions;
    positive: PaletteColorOptions;
    negative: PaletteColorOptions;
    medium: PaletteColorOptions;
    information: PaletteColorOptions;
    equity: PaletteColorOptions;
    safe: PaletteColorOptions;
    convertible: PaletteColorOptions;
    debt: PaletteColorOptions;
    revenue: PaletteColorOptions;
  }
}

// add custom properties: https://material-ui.com/guides/typescript/#customization-of-theme

const BASE_THEME_OPTIONS = {
  typography: {
    fontFamily: [
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: '#292D44',
    },
  },
  palette: {
    // THEME COLORS
    // ==============
    primary: {
      superdark: '#20295B',
      dark: '#2F3D88',
      main: '#3F51B5',
      light: '#8C97D3',
      superlight: '#D9DCF0',
      extralight: '#E8EAF6',
    },
    secondary: {
      extralight: '#FFF3CD',
      light: '#FFDA6A',
      main: '#FFC107',
      dark: '#B28602',
    },
    greyScale: {
      superdark: '#292D44',
      dark: '#7F818F',
      main: '#A9ABB5',
      light: '#D4D5DA',
      superlight: '#FAFAFA',
      extralight: '#FFFFFF',
    },
    // ALERTS
    // ==============
    positive: {
      // main: "#3E8E41",
      main: '#4CAF50',
      light: '#94CF96',
      superlight: '#DBEFDC',
    },
    negative: {
      main: '#C2433A',
      light: '#CE6961',
      superlight: '#F3D9D8',
    },
    medium: {
      main: '#FFC107',
      light: '#FFDA6A',
      superlight: '#FFF3CD',
    },
    information: {
      main: '#63B3F6',
      light: '#8AC6F8',
      superlight: '#E8F4FE',
    },
    // SECURITIES
    // ==============
    equity: {
      main: '#E1BC29',
      light: '#FFECB3',
    },
    safe: {
      main: '#737DD3',
      light: '#BBDEFB',
    },
    debt: {
      main: '#688D7D',
      light: '#C8E6C9',
    },
    convertible: {
      main: '#BDA0BC',
      light: '#D1C4E9',
    },
    revenue: {
      main: '#5D4037',
      light: '#D7CCC8',
    },
  },
};

const defaultTheme = responsiveFontSizes(createTheme(BASE_THEME_OPTIONS));

export default defaultTheme;

// markdown renderer theme
const mdxOverrides = {};
const mdxTheme = createTheme({ ...BASE_THEME_OPTIONS, ...mdxOverrides });

mdxTheme.typography.h1 = {
  fontSize: '3rem',
};
mdxTheme.typography.h2 = {
  fontSize: '2.6rem',
};
mdxTheme.typography.h3 = {
  fontSize: '2rem',
};
mdxTheme.typography.h4 = {
  fontSize: '1.5rem',
};
mdxTheme.typography.h5 = {
  fontSize: '1.25rem',
};
mdxTheme.typography.h6 = {
  fontSize: '1.125rem',
};

export { mdxTheme };
