import { combineReducers } from '@reduxjs/toolkit';

import communityReducer from '@/features/community/api/slice';
import globalsReducer from '@/features/globals/slice';
import marketsReducer from '@/features/markets/api/slice';
import notificationsReducer from '@/features/notifications/api/slice';
import searchReducer from '@/features/search/slice';
import authReducer from '@/features/auth/api/slice';
import feedReducer from '@/features/feed/api/slice';

const rootReducer = combineReducers({
  community: communityReducer,
  globals: globalsReducer,
  markets: marketsReducer,
  notifications: notificationsReducer,
  search: searchReducer,
  auth: authReducer,
  feed: feedReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
