import api from 'src/api';
import { SearchResponse } from 'typings/search';

export default function searchApi() {
  async function getSearchResults(
    token:string,
    searchterm: string,
    selectedType: 'all' | 'platform' | 'company',
  ): Promise<SearchResponse> {
    let queryParams = {

    };
    switch (selectedType) {
      case 'platform':
        queryParams = { platform__verbose_name__icontains: searchterm };
        break;
      case 'company':
        queryParams = { company_name__icontains: searchterm };
        break;
      default:
        queryParams = { q: searchterm };
        break;
    }
    const response = await api(token).get('/search/offerings/', {
      params: queryParams,
    });
    return response.data;
  }
  return { getSearchResults };
}
