/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Notification } from 'typings/notifications';
import useNotificationsApi from './api';

interface NotificationsState {
  new_count: number
  next: string | undefined
  notifications: Notification[]
  isLoading: boolean
  fetched: boolean
  error: string | null
}

const notificationsInitialState: NotificationsState = {
  notifications: [],
  next: undefined,
  new_count: 0,
  isLoading: false,
  fetched: false,
  error: null,
};

/* Async Thunks
   ========================
*/
const api = useNotificationsApi();

// fetch notifications
export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async ({ cursor }: { cursor?: string }, { getState }) => {
    const { auth } = getState();
    return api.fetchNotifications(auth.token || '', cursor);
  },
);

// fetch new notifications
export const fetchNewNotifications = createAsyncThunk(
  'notifications/fetchNewNotifications',
  async (_, { getState }) => {
    const { auth } = getState();
    return api.fetchNewNotifications(auth.token || '');
  },
);

// mark a notification as read
export const markNotificationAsRead = createAsyncThunk(
  'notifications/markNotificationAsRead',
  async ({ notificationId }: { notificationId: number }, { getState }) => {
    const { auth } = getState();
    return api.markNotificationAsRead(auth.token || '', notificationId);
  },
);

// dismiss bell icon badge (X new notifications)
export const dismissBadge = createAsyncThunk(
  'notifications/dismissBadge',
  async (_, { getState }) => {
    const { auth } = getState();
    return api.markAllNotificationAsOld(auth.token || '');
  },
);

const notifications = createSlice({
  name: 'notifications',
  initialState: notificationsInitialState,
  reducers: {
    reset: () => notificationsInitialState,
  },
  extraReducers: (builder) => {
    builder
      // fetch new notifications
      .addCase(fetchNewNotifications.fulfilled, (state, action) => {
        // this is the API response, so we don't control the naming convention
        // we could do something like { new_count: newCount }, but this is only for one line...
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { count } = action.payload;
        state.new_count = count;
        state.isLoading = false;
        state.error = null;
        state.fetched = true;
      })
      // fetch notifications
      .addCase(fetchNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        const { results, next } = action.payload;
        state.next = next;
        state.notifications = results;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        const { message } = action.error;
        state.isLoading = false;
        state.error = message || null;
      })
      // mark a notification as read
      .addCase(markNotificationAsRead.pending, () => {
      })
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        if (action.payload) {
          const toChange = state.notifications.find(
            (x) => x.id === action.meta.arg.notificationId,
          );
          if (toChange) {
            toChange.marked_as_read = true;
          }
        }
      })
      .addCase(markNotificationAsRead.rejected, (state, action) => {
        const { message } = action.error;
        state.error = message || null;
      })
      // mark a notification as read
      .addCase(dismissBadge.pending, () => {
      })
      .addCase(dismissBadge.fulfilled, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { new_count } = action.payload;
        state.new_count = new_count;
        state.notifications = state.notifications.map(
          (x) => ({ ...x, counts_as_new: false }),
        );
      })
      .addCase(dismissBadge.rejected, (state, action) => {
        const { message } = action.error;
        state.error = message || null;
      });
  },
});

export default notifications.reducer;

export const { reset } = notifications.actions;
