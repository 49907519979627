import { reset as feedReset } from '../features/feed/api/slice';
import { reset as communityReset } from '../features/community/api/slice';
import { reset as globalsReset } from '../features/globals/slice';
import { reset as marketsReset } from '../features/markets/api/slice';
import { reset as notificationsReset } from '../features/notifications/api/slice';
import { AppThunk } from './store';

const reset = (): AppThunk => async (dispatch) => {
  dispatch(feedReset());
  dispatch(communityReset());
  dispatch(globalsReset());
  dispatch(marketsReset());
  dispatch(notificationsReset());
};

export default reset;
