import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Button, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

interface Props {
  error: Error,
  resetErrorBoundary: () => void
}

const defaultProps = {

};

const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  const classes = useStyles();
  // const { t } = useTranslation('components');
  return (
    <Container className={classes.root}>
      <Alert
        severity="error"
        action={(
          <Button color="inherit" size="small" onClick={resetErrorBoundary}>
            {/* {t('errorFallback.reset_app')} */}
            reset
          </Button>
  )}
      >
        <AlertTitle>Something went wrong</AlertTitle>
        {/* <AlertTitle>{t('errorFallback.something_went_wrong')}</AlertTitle> */}
        <pre>{error.message}</pre>
      </Alert>
    </Container>
  );
};

ErrorFallback.defaultProps = defaultProps as unknown as Partial<Props>;

export default ErrorFallback;
