import api from '../../../api';

import {
  FetchFeedPromise,
} from '../../../../typings/feed/api.d';

// this "hook" is basically just a wrapper for all the async thunk API functions
// this way they can have the same name
// e.g. fetchMyBoards calls api.fetchMyBoards

export default function useFeedApi() {
  // fetch the post list for a specific board
  async function fetchFeed(
    token: string,
    // ordering,
    // timeframe,
    next?: string,
  ): Promise<FetchFeedPromise> {
    if (next) {
      const response = await api(token).get(`${next}`);
      return response.data;
    }
    const URI = '/feed/items/';
    const response = await api(token).get(URI);
    return response.data;
  }

  async function fetchWeeklySummarySidebar(token: string) {
    const response = await api(token).get('/feed/items/weekly_summary/');
    return response.data;
  }

  return {
    fetchFeed,
    fetchWeeklySummarySidebar,
  };
}
