import { Creator, UrlShortMetadata } from 'typings/community/api';
import { FetchFeedPromise } from './api.d';

export interface FeedState {
  isLoading: boolean
  error: string | null
  data: FetchFeedPromise
  weeklySummary: {
    isLoading: boolean
    error: string | null
    data: FeedPostWeeklySummary | null
  }
}

export interface FeedItem {
  item: FeedPostWeeklySummary | FeedPostWatchlistUpdate | FeedPostItem | FeedOfferingItem
  created_at: string
  item_type: 'WATCHLIST_UPDATE' | 'WEEKLY_SUMMARY' | 'OFFERING' | 'POST'
}
export interface FeedPostWeeklySummary {
  created_at: string
  week_number: string
  new_offers_count: number
  closed_offers_count: number
  total_amount_raised: number
  new_investors_count: number
  offers:{
    offer: {
      slug: string
      company_name: string
      offering_name: string
      cover_img: string | null
    }
    amount_raised_diff: number
    investors_count_diff:number
  }[]

}
export interface FeedPostWatchlistUpdate {
  created_at: string
  item:{
    id: number
    update_type: 'WITHDRAWAL' | 'CLOSE_UNSUCCESS' | 'CLOSE_SUCCESS' | 'FUNDING_GOAL_MIN' | 'FUNDING_GOAL_MAX'
    created_at: string
    offer: {
      slug: string
      offering_name: string | null
      company_name: string
      cover_img: string | null
      funding_goal_min: number
      funding_goal_max: number
    }
    financial_data: {
      withdrawal_link?: string
      amount_raised? : number
      amount_left_max?: number
      amount_left_min?: number
      amount_left?: number
      investors_count?: number
      financial_data: {
        funding_raised: []
        investors_count: []
      }
      funding_raised_timeseries: [[string, number]]
      investors_count_timeseries: [[string, number]]
    }
  }
}
export interface FeedPostItem {
  title: string
  slug: string
  week_number: string
  truncated_content: string
  content: string
  pinned: boolean
  external_url: string | null
  external_url_short_metadata?: UrlShortMetadata | null
  created_at: string
  updated_at: string
  hotness: number
  confidence_sort_value: number
  board:{
    name: string
    slug: string
    description: string
    board_region: string
    image: string | null
    color: string
    textColor: string
  }
  tags: {
    name: string | null
  }[]
  upvotes_count: number
  has_user_upvoted: boolean
  user_can_modify: boolean
  marked_as_deleted: boolean
  creator:Creator
  edit_counter: number
  post_should_be_highlighted: boolean
  comment_counter: number;
}

export interface FeedOfferingItem {
  week_number: string
  slug: string
  is_new: boolean
  status: string
  country: string
  platform: {
    id: string
    name: string
    logo: string | null
    rounded_logo: string | null
  }
  images: {
    logo:{
      md: string|null
      xs:string|null
    }
    author_url: string | null
    author_name: string | null
    is_unsplash: string | null
    offer_image: {
      lg: string | null
      md: string|null
      xs:string|null
    }
  }
  investors_count: number
  security_type: 'EQUITY' | 'DEBT' | 'CONVERTIBLE' | 'SAFE' | 'OTHER'
  raised: number | null
  /*  raised: {
    dated: string
    amount: number
  } | null */
  ticket_amount_minimum: number| null
  funding_goal_min: number |null
  funding_goal_max: number
  currency: string
  regulation: string
  company_name: string
  create_at: string
  start_date: string
  deadline_date: string
  end_date: string
  days_remaining: number
  days_since_startdate: number
  timeline_progress: number
  has_user_subscribed: boolean
  industry_category: string
}

// type guards
export function isFeedPostItem(
  item: FeedPostItem | FeedOfferingItem | FeedPostWatchlistUpdate | FeedPostWeeklySummary,
): item is FeedPostItem {
  return !!(item as FeedPostItem).title;
}
