/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SearchState, SearchType } from 'typings/search';
import searchApi from './api';

const api = searchApi();

const initialState: SearchState = {
  search: {
    isLoading: false,
    error: null,
    data: {
      next: null,
      previous: null,
      results: [],
    },
  },
};

// fetch list of offerings
export const fetchSearchResults = createAsyncThunk(
  'search/fetchSearchResults',
  async ({ searchterm, selectedType } : SearchType, { getState }) => {
    const { auth } = getState();
    return api.getSearchResults(auth.token || '', searchterm, selectedType);
  },
);

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // fetch offerings
      .addCase(fetchSearchResults.pending, (state) => {
        state.search.isLoading = true;
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.search.data = action.payload;
        state.search.isLoading = false;
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        const { message } = action.error;
        state.search.error = message || null;
        state.search.isLoading = false;
      });
  },
});

export { search };
export default search.reducer;
export const { reset } = search.actions;
