// React, Next, Libraries
import React from 'react';
import Link from 'next/link';

/* Material-UI */
import {
  makeStyles, darken,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import LaunchIcon from '@material-ui/icons/Launch';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import clsx from 'clsx';

/* Styles */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  rootStyled: {
    fontSize: 16,
    color: theme.palette.primary.main,
    '& >a': {
      textDecoration: 'none',
      color: darken(theme.palette.primary.main, 0.2),
    },
    '& > a:hover': {
      backgroundColor: darken(theme.palette.secondary.contrastText, 0.1),
      borderRadius: '5px',
    },
    display: 'flex',
    alignItems: 'center',
  },
  rootUnstyled: {
    textDecoration: 'none',
    color: 'inherit',
    '& > a:focus, a:hover, a:visited, a:link, a:active': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& >a': {
      textDecoration: 'none',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

interface RenderProps {
  iconName: 'newtab' | 'email'
}

const RenderIcon = ({ iconName = 'newtab' }: RenderProps) => {
  const classes = useStyles({ inheritColor: true });
  switch (iconName) {
    case 'newtab':
      return (
        <LaunchIcon
          fontSize="small"
          color="inherit"
          className={classes.icon}
        />
      );
    case 'email':
      return (
        <AlternateEmailIcon
          fontSize="small"
          color="inherit"
          className={classes.icon}
        />
      );
    default:
      return <></>;
  }
};

interface Props {
  href: string,
  children: React.ReactNode
  styled?: boolean
  iconName?: 'newtab' | 'email'
  className?: string
}

const defaultProps = {
  styled: false,
};

const LinkComponent = ({
  children, href, styled = false, iconName, className,
}: Props) => {
  const classes = useStyles();
  return (
    <Typography
      className={clsx(
        className,
        classes.root,
        styled ? classes.rootStyled : classes.rootUnstyled,
      )}
    >
      {iconName && <RenderIcon {...{ iconName }} />}
      {href.startsWith('http') ? (
        <a href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <Link href={href}>
          {children}
        </Link>
      )}
    </Typography>
  );
};

LinkComponent.defaultProps = defaultProps as unknown as Partial<Props>;

export default LinkComponent;
