/* eslint-disable @typescript-eslint/quotes */
import { withStyles } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

const style = () => ({
  header: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '30.8px',
    letter: '0.75px',
  },
  header2: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '30.8px',
    letter: '0.75px',
  },
  title: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '26.4px',
    letter: '0.75px',
  },
  title2: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '26.4px',
    letter: '0.75px',
  },
  subheader: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '22px',
    letter: '0.75px',
  },
  subheader2: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '22px',
    letter: '0.75px',
  },
  base: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '23.8px',
    letter: '0.75px',
  },
  base2: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '17px',
    lineHeight: '22.1px',
    letter: '0.75px',
  },
  baseBold: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '22.1px',
    letter: '0.75px',
  },
  base2bold: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '22.1px',
    letter: '0.75px',
  },
  base2regular: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '22.1px',
    letter: '0.75px',
  },
  secondary: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21.45px',
    letter: '0.75px',
  },
  secondary2: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '15px',
    lineHeight: '21.45px',
    letter: '0.75px',
  },
  secondaryBold: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '21.45px',
    letter: '0.75px',
  },
  secondary2bold: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '21.45px',
    letter: '0.75px',
  },
  secondary2medium: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '21.45px',
    letter: '0.75px',
  },
  small: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18.59px',
    letter: '0.75px',
  },
  small2: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '18.59px',
    letter: '0.75px',
  },
  smallBold: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '18.59px',
    letter: '0.75px',
  },
  small2bold: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '18.59px',
    letter: '0.75px',
  },
  small2medium: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18.59px',
    letter: '0.75px',
  },
  extraSmall2bold: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '18.59px',
    letter: '0.75px',
  },
});

/**
 * Overrides default material UI typography with some additional variants
 */
const CUSTOM_VARIANTS = [
  'header',
  'title',
  'subheader',
  'base',
  'baseBold',
  'secondary',
  'secondaryBold',
  'small',
  'smallBold',
  'header2',
  'title2',
  'subheader2',
  'base2',
  'base2bold',
  'base2regular',
  'secondary2',
  'secondary2bold',
  'secondary2medium',
  'small2',
  'small2bold',
  'small2medium',
  'extraSmall2bold',
] as const;
type CustomVariants = typeof CUSTOM_VARIANTS[number];
interface Props extends Omit<TypographyProps, 'variant'> {
  variant: 'inherit' | Variant | undefined | CustomVariants;
}
const defaultProps = {
  align: 'left',
};
const Typography = ({ variant, classes, className, ...props }: Props) => {
  const isCustom =
    classes && variant && Object.keys(classes).indexOf(variant) > -1;
  return (
    <MuiTypography
      className={
        isCustom
          ? clsx(classes && variant && classes[variant as Variant], className)
          : className
      }
      variant={isCustom ? undefined : (variant as TypographyProps['variant'])}
      // variant={variant}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
Typography.defaultProps = (defaultProps as unknown) as Partial<Props>;

// @ts-ignore
export default withStyles(style)(Typography);
