import {
  CreateCommentPromise,
  CreatePostPromise,
  DeleteCommentPromise,
  DeletePostPromise,
  FetchCommentsFromNodePromise,
  FetchInfoForBoardPromise,
  FetchMyBoardsPromise,
  FetchAllBoardsPromise,
  FetchPostListForBoardPromise,
  ModifyCommentPromise,
  ModifyPostPromise,
  ToggleLikeForCommentPromise,
  ToggleLikeForPostPromise,
  FetchPostPromise,
  FetchCommentTreePromise,
} from 'typings/community/api';
import api from '../../../api';

// this "hook" is basically just a wrapper for all the async thunk API functions
// this way they can have the same name
// e.g. fetchMyBoards calls api.fetchMyBoards

export default function useCommunityApi() {
  // fetch list of "my boards"
  async function fetchMyBoards(token: string): Promise<FetchMyBoardsPromise[]> {
    const response = await api(token).get('/community/my-boards/');
    return response.data;
  }
  // fetch list of all available boards
  async function fetchAllBoards(token: string): Promise<FetchAllBoardsPromise[]> {
    const response = await api(token).get('/community/boards/');
    return response.data;
  }

  // add a board to "my boards" or remove it from that list
  async function toggleBoardSubscription(
    token: string, boardId: string,
  ) {
    await api(token).post(
      `/community/boards/${boardId}/toggle_subscription/`,
      {},
    );
  }

  // fetch the post list for a specific board
  async function fetchPostListForBoard(
    token: string,
    boardId: string,
    // ordering,
    // timeframe,
    next?: string,
  ): Promise<FetchPostListForBoardPromise> {
    if (next) {
      const response = await api(token).get(next);
      return response.data;
    }
    const URI = `/community/posts/?board=${boardId}`;
    const response = await api(token).get(URI);
    return response.data;
  }
  // fetch the board info for a specific board
  async function fetchInfoForBoard(
    token: string, boardId: string,
  ): Promise<FetchInfoForBoardPromise> {
    const response = await api(token).get(`/community/boards/${boardId}/`);
    return response.data;
  }

  // create a new post
  async function createPost(
    token: string,
    title: string,
    content: string,
    external_url?: string,
    board?: string,
    tags?: string[],
  ): Promise<CreatePostPromise> {
    const response = await api(token).post('/community/posts/', {
      title,
      content,
      external_url,
      board,
      tags: tags || [],
    });
    return response.data;
  }

  // modify a post
  async function modifyPost(
    token: string,
    postId:string,
    content:string,
  ): Promise<ModifyPostPromise> {
    const response = await api(token).patch(
      `/community/posts/${postId}/`,
      { content },
    );
    return response.data;
  }

  // delete a post
  async function deletePost(token: string, postId: string): Promise<DeletePostPromise> {
    const response = await api(token).delete(
      `/community/posts/${postId}/`,
      {},
    );
    return response.data;
  }

  // toggle the "like" status of a post
  async function toggleLikeForPost(
    token: string, postId: string,
  ): Promise<ToggleLikeForPostPromise> {
    const response = await api(token).post(
      `/community/posts/${postId}/toggle_upvote/`,
      {},
    );
    return {
      postId,
      data: response.data,
    };
  }

  // fetch a post
  async function fetchPost(
    token: string, postId: string,
  ): Promise<FetchPostPromise> {
    const response = await api(token).get(
      `/community/posts/${postId}/`,
      {},
    );
    return response.data;
  }

  // fetch comment tree
  async function fetchCommentTree(
    token: string, postId: string, cursor?: string,
  ): Promise<FetchCommentTreePromise> {
    if (cursor) {
      const response = await api(token).get(cursor);
      return response.data;
    }
    const response = await api(token).get(
      `/community/replies/?post=${postId}`,
      {},
    );
    return response.data;
  }

  // fetch comments from a base node (non-root)
  async function fetchCommentsFromNode(
    token: string,
    nodesToFetch: string[],
  ): Promise<FetchCommentsFromNodePromise> {
    const response = await api(token).get(
      `/community/replies/fetch_replies_by_id/?ids=${nodesToFetch}`,
    );
    return response.data;
  }
  // create a new comment
  async function createComment(
    token: string,
    content: string,
    parentPostId: string | undefined,
    parentCommentId: string | undefined,
  ): Promise<CreateCommentPromise> {
    const response = await api(token).post('/community/replies/', {
      content,
      parent_post: parentPostId,
      parent_reply: parentCommentId,
    });
    return response.data;
  }

  // modify a comment
  async function modifyComment(
    token: string,
    commentId:string,
    content:string,
  ): Promise<ModifyCommentPromise> {
    const response = await api(token).patch(
      `/community/replies/${commentId}/`,
      { content },
    );
    return response.data;
  }

  // delete a comment
  async function deleteComment(token: string, commentId:string): Promise<DeleteCommentPromise> {
    const response = await api(token).delete(
      `/community/replies/${commentId}/`,
      {},
    );
    return response.data;
  }

  // toggle the "like" status of a comment
  async function toggleLikeForComment(
    token: string, commentId: string,
  ): Promise<ToggleLikeForCommentPromise> {
    const response = await api(token).post(
      `/community/replies/${commentId}/toggle_upvote/`,
      {},
    );
    return {
      commentId,
      data: response.data,
    };
  }

  return {
    fetchMyBoards,
    fetchAllBoards,
    toggleBoardSubscription,
    fetchPostListForBoard,
    fetchInfoForBoard,
    createPost,
    modifyPost,
    deletePost,
    toggleLikeForPost,
    fetchPost,
    fetchCommentTree,
    fetchCommentsFromNode,
    createComment,
    modifyComment,
    deleteComment,
    toggleLikeForComment,
  };
}
