/* eslint-disable no-param-reassign */
// param reassign is safe because it's redux-toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toggleAddWatchlist } from '@/features/markets/api/slice';
import { toggleLikeForPost } from '@/features/community/api/slice';

import useFeedApi from './api';
import { FeedState, isFeedPostItem } from '../../../../typings/feed/state.d';

const feedInitialState: FeedState = {
  isLoading: false,
  error: null,
  data: {
    next: null,
    previous: null,
    results: [],
  },
  weeklySummary: {
    isLoading: false,
    error: null,
    data: null,
  },
};

const api = useFeedApi();

interface FetchFeedTypes {
  // ordering,
  // timeframe,
  cursor?: string | undefined
}
// fetch the post list for a specific board
export const fetchFeed = createAsyncThunk(
  'feed/fetchFeed',
  async ({
    // ordering,
    // timeframe,
    cursor,
  }: FetchFeedTypes, { getState }) => {
    const { auth } = getState();
    return api.fetchFeed(auth.token || '', cursor);
  },
);

export const fetchWeeklySummarySidebar = createAsyncThunk(
  'feed/fetchWeeklySummarySidebar', async (v:void, { getState }) => {
    const { auth } = getState();
    return api.fetchWeeklySummarySidebar(auth.token || '');
  },
);

const feed = createSlice({
  name: 'feed',
  initialState: feedInitialState,
  reducers: {
    reset: () => feedInitialState,
  },
  extraReducers: (builder) => {
    builder
    // sidebar weekly summary
      .addCase(fetchWeeklySummarySidebar.pending, (state) => {
        state.weeklySummary.isLoading = true;
      })
      .addCase(fetchWeeklySummarySidebar.fulfilled, (state, action) => {
        state.weeklySummary.isLoading = false;
        state.weeklySummary.data = action.payload;
      })
      // fetch post list for specific board
      .addCase(fetchFeed.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFeed.fulfilled, (state, action) => {
        // if we have a cursor that means we have to append
        if (action.meta.arg.cursor) {
          const { results, next } = action.payload;
          state.data.results = [...state.data.results, ...results];
          state.data.next = next;
        } else {
          state.data = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(fetchFeed.rejected, (state, action) => {
        const { message } = action.error;
        state.isLoading = false;
        state.error = message || null;
      })
    // toggle like for post
      .addCase(toggleLikeForPost.pending, (state, action) => {
        const postToToggle = state.data.results.find(
          (x) => x.item.slug === action.meta.arg.postId,
        );
        if (postToToggle && isFeedPostItem(postToToggle.item)) {
          postToToggle.item.upvotes_count += postToToggle.item.has_user_upvoted ? -1 : 1;
          postToToggle.item.has_user_upvoted = !postToToggle.item.has_user_upvoted;
        }
      })
      .addCase(toggleLikeForPost.rejected, (state, action) => {
        const postToToggle = state.data.results.find(
          (x) => (x.item_type === 'POST' && x.item.slug === action.meta.arg.postId),
        );
        if (postToToggle && isFeedPostItem(postToToggle.item)) {
          postToToggle.item.upvotes_count += postToToggle.item.has_user_upvoted ? -1 : 1;
          postToToggle.item.has_user_upvoted = !postToToggle.item.has_user_upvoted;
        }
      })
      .addCase(toggleAddWatchlist.pending, (state, action) => {
        const { slug } = action.meta.arg;
        state.data.results = state.data.results.filter((item) => item.item_type !== 'WATCHLIST_UPDATE' || item.item.offer?.slug !== slug);

        const offeringToToggle = state.data.results.filter((item) => item.item_type === 'OFFERING').find(
          (item) => item.item.slug === slug,
        );
        if (offeringToToggle) {
          offeringToToggle.item.has_user_subscribed = !offeringToToggle.item.has_user_subscribed;
        }
      });
  },
});

export default feed.reducer;

export const { reset } = feed.actions;
