import {
  CheckPromise,
  ConfirmEmailPromise,
  GetDataPromise,
  RegisterPromise,
  SignInPromise,
  SignOutPromise,
  RequestPasswordPromise,
  ConfirmPasswordPromise,
  ChangeObject,
  ChangePasswordPromise,
  FacebookPromise,
  GooglePromise,
  RestorePromise,
} from 'typings/auth';
import api from '../../../api';

export default function authApi() {
  async function register(
    email: string,
    firstName:string,
    lastName: string,
    password1: string,
    password2: string,
  ): Promise<RegisterPromise> {
    const response = await api().post(
      '/users/auth/registration/', {
        email,
        password1,
        password2,
        first_name: firstName,
        last_name: lastName,
      },
    );
    return response.data;
  }

  async function confirmEmail(key: string): Promise<ConfirmEmailPromise> {
    const response = await api().post(
      '/users/auth/registration/verify-email/', {
        key,
      },
    );
    return response.data;
  }

  async function requestPasswordReset(email: string): Promise<RequestPasswordPromise> {
    const response = await api().post(
      '/users/password-reset/', {
        email,
      },
    );
    return response.data;
  }

  async function confirmPasswordReset(
    new_password1:string,
    new_password2:string,
    uid: string,
    token:string,
  ): Promise<ConfirmPasswordPromise> {
    const response = await api().post(
      '/users/auth/password/reset/confirm/', {
        new_password1,
        new_password2,
        uid,
        token,
      },
    );
    return response.data;
  }

  async function checkEmail(email:string): Promise<CheckPromise> {
    const response = await api().get(
      `/users/check-email/?email=${encodeURIComponent(email)}`,
      {
      },
    );
    return response.data;
  }
  async function signInEmail(email: string, password: string): Promise<SignInPromise> {
    const response = await api().post(
      '/users/auth/login/',
      {
        email,
        password,
      },
    );
    if (response.status < 300) {
      localStorage.setItem('token', response.data.key);
      return { key: response.data.key };
    }
    return Promise.reject();
  }

  async function getUserData(token: string): Promise<GetDataPromise> {
    const response = await api(token).get(
      '/users/auth/user/',
      {
      },
    );
    return response.data;
  }

  async function signInGoogle(token: string, code: string): Promise<GooglePromise> {
    const response = await api().post(
      '/users/auth/google/',
      {
        access_token: token,
        code,
      },
    );
    return { key: response.data.key };
  }

  async function signInFacebook(token: string, code: string): Promise<FacebookPromise> {
    const response = await api().post(
      '/users/auth/facebook/',
      {
        access_token: token,
        code,
      },
    );
    return { key: response.data.key };
  }

  async function signOut(token: string): Promise<SignOutPromise> {
    const response = await api(token).post(
      '/users/auth/logout/',
      {
      },
    );
    return response.data;
  }

  async function restoreToken(token: string | null): Promise<RestorePromise> {
    if (token) {
      const response = await api(token).get(
        '/users/auth/user/',
        {
        },
      );
      return {
        token,
        user: response.data,
      };
    }
    // no token was found
    return Promise.reject();
  }

  // ============== Settings ==============

  async function changePassword(
    new_password1:string,
    new_password2:string,
    token:string,
  ):Promise<ChangePasswordPromise> {
    const response = await api(token).post(
      '/users/auth/password/change/', {
        new_password1,
        new_password2,
      },
    );
    return response.data;
  }

  async function saveUserInfo(token:string, changeObject:ChangeObject) {
    const response = await api(token).patch(
      '/users/auth/user/', changeObject,
    );
    return response.data;
  }

  return {
    register,
    confirmEmail,
    requestPasswordReset,
    confirmPasswordReset,
    signInEmail,
    checkEmail,
    signInGoogle,
    signInFacebook,
    signOut,
    restoreToken,
    getUserData,
    changePassword,
    saveUserInfo,
  };
}
