import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '@/features/globals/slice';
import { RootState } from '@/store/rootReducer';

let displayed: (string|number)[] = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const {
    snackbars,
  } = useSelector((state: RootState) => state.globals);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: number | string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: number | string) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    snackbars.forEach(({
      key, message, options = {}, dismissed = false,
    }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options?.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar({ key: myKey }));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [snackbars, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
