import { MarketsState } from 'typings/markets/state';
/** @todo completar initialFilters */
const initialFilters = {
  previousRounds: false,
  serialFundraisers: false,
  successful: false,
  acredittedInvestor: false,
  platforms: [],
  sectors: [],
  specialInterest: [],
  status: [
    {
      id: 'LIVE',
      name: 'LIVE',
      verbose_name: 'LIVE',
      live_offerings: 0,
      offers: 0,
      checked: true,
    },
    {
      id: 'EXPIRED',
      name: 'EXPIRED',
      verbose_name: 'EXPIRED',
      live_offerings: 0,
      offers: 0,
      checked: false,
    },
    {
      id: 'TESTING THE WATERS',
      name: 'TESTING THE WATERS',
      verbose_name: 'TESTING THE WATERS',
      live_offerings: 0,
      offers: 0,
      checked: false,

    },
    {
      id: 'UNKNOWN',
      name: 'UNKNOWN',
      verbose_name: 'UNKNOWN',
      live_offerings: 0,
      offers: 0,
      checked: false,

    },
  ],
  stage: [],
  securityType: [{
    id: 'EQUITY',
    name: 'Equity',
    offers: 0,
    verbose_name: 'EQUITY',
    live_offerings: 0,
    checked: false,
  },
  {
    id: 'SAFE',
    name: 'SAFE',
    offers: 0,
    verbose_name: 'SAFE',
    live_offerings: 0,
    checked: false,
  },
  {
    id: 'DEBT',
    name: 'Debt',
    offers: 0,
    verbose_name: 'DEBT',
    live_offerings: 0,
    checked: false,
  },
  {
    id: 'CONVERTIBLE',
    name: 'Convertible',
    offers: 0,
    verbose_name: 'CONVERTIBLE',
    live_offerings: 0,
    checked: false,
  },
  {
    id: 'OTHER',
    name: 'Other',
    offers: 0,
    verbose_name: 'OTHER',
    live_offerings: 0,
    checked: false,
  },
  ],
  category: [],
  searchTerm: '',
  target: null,
  startDate: {
    choices: [
      null,
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '9',
      '12',
      '24',
      '36',
      '72',
    ],
    selected: null,
  },
  teamSize: null,
  numberOfInvestors: null,
  valuationCap: null,
  minimumTicket: null,
};

const marketsInitialState: MarketsState = {
  offerings: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    isLoading: false,
    error: null,
  },
  offering: {
    selectedId: null,
    data: null,
    isLoading: false,
    error: null,
  },
  watchlist: {
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    isLoading: false,
    error: null,
  },
  newFilters: {
    isLoading: false,
    error: null,
    initialValues: initialFilters,
    hasAppliedFiltersFromQueryParams: false,
    hasFetchedFilterLimits: false,
    data: initialFilters,
    appliedFilters: 0,
    searchTerm: null,
    selectedType: 'grid',
    page: 1,
    sortBy: '-startdate',
    banner: [],
  },
  specialOfferings: {
    data: {
      trending: [],
      new: [],
      ending_soon: [],
    },
    isLoading: false,
    error: null,
  },
  dataBanner: {
    data: [],
    isLoading: false,
    error: null,
  },
};

export default marketsInitialState;
