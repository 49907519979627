import React, { ReactNode, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { useTranslation } from 'next-i18next';

import { makeStyles, useTheme } from '@material-ui/core/styles';

// import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useRouter } from 'next/router';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckIcon from '@material-ui/icons/Check';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { toggleCookieConfiguration } from '@/features/globals/slice';
import { RootState } from '@/store/rootReducer';
import Typography from '@/features/markets/components/Typography';
import Button from '@/features/markets/components/Button';
import { Background } from '../ui-components/SVGBackground';
import { Diamond } from '../ui-components/SVGIcons';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    overflow: 'hidden',
    zIndex: 3000,
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    backgroundColor: '#fff',
    right: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[10],
    // boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
  },
  svgBg: {
    position: 'absolute',
    bottom: -10,
    right: 0,
    transform: 'scale(-1,1)',
    // [theme.breakpoints.up('sm')]: {
    //   clipPath: 'polygon(0% 40%, 0 100%, 15% 100%)',
    // },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    // [theme.breakpoints.up('md')]: {
    //   clipPath: 'polygon(0% 50%, 0 100%, 25% 100%)',
    // },
    [theme.breakpoints.up('sm')]: {
      // clipPath (X -> 0, Y -> ??) (X -> 0, Y -> 100%) (X -> ??, Y -> 100%)
      clipPath: 'polygon(0% 45%, 0 100%, 27% 100%)',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
  denyButton: {
    // color: theme.palette.grey['400'],
    // textTransform: 'uppercase',
  },
  acceptAllButton: {
    // backgroundColor: '#fff',
  },
  circularProgressDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100vh',
  },
  buttons: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
      '& > *': {
        marginBottom: theme.spacing(1),
      },
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& > *': {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      flexWrap: 'wrap',
    },
  },
}));

interface Props {
  children: ReactNode
}

export default function Cookies({ children }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // i18n
  const { t } = useTranslation('common');

  const { asPath, push } = useRouter();
  const dispatch = useDispatch();
  const { cookieConfigOpen } = useSelector((state: RootState) => state.globals);

  // cookies
  const [cookies, setCookie] = useCookies();
  const [cookieSnackbarOpen, setCookieSnackbarOpen] = useState(false);

  const handleCustomize = () => {
    dispatch(toggleCookieConfiguration());
    setCookieSnackbarOpen(false);
  };

  const handleAllow = () => {
    setCookie('essential', 1, {
      path: '/', expires: new Date('2099-12-31'), secure: true, sameSite: 'lax',
    });
    setCookie('analytics', 1, {
      path: '/', expires: new Date('2099-12-31'), secure: true, sameSite: 'lax',
    });
    setCookieSnackbarOpen(false);
  };
  const handleReject = () => {
    setCookie('essential', 1, {
      path: '/', expires: new Date('2099-12-31'), secure: true, sameSite: 'lax',
    });
    setCookieSnackbarOpen(false);
  };

  useEffect(() => {
    if (!cookies.essential && !cookieConfigOpen) {
      setCookieSnackbarOpen(true);
    }
  }, [cookies]);

  // initialize google analytics if cookie is set
  useEffect(() => {
    if (cookies.analytics) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA || '', {
        debug: false,
        titleCase: false,
      });
    }
  }, [cookies.analytics]);

  useEffect(() => {
    if (cookies.analytics && asPath) {
      ReactGA.pageview(asPath);
    }
  }, [cookies.analytics, asPath]);

  // the conditional snackbar causes issues with SSR
  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  // if the component hasn't mounted yet, we simply return a blank screen.
  // Given that this component includes children (the ENTIRE tree, actually)
  // we get a blank page, which is not ideal...
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return (
      <div className={classes.circularProgressDiv}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      {children}
      {cookieSnackbarOpen && (
      <div className={classes.root}>
        <div className={classes.svgBg}>
          <Background variant="dark" />
        </div>
        <div className={classes.content}>
          <Typography variant="small">
            {t('cookies.message')}
          </Typography>
          <div className={classes.buttons}>
            {/* <ButtonLink href="/privacy-policy" className={classes.denyButton}>
              {t('cookies.privacy_policy')}
            </ButtonLink> */}
            <Button
              startIcon={<InfoOutlinedIcon />}
              className={classes.denyButton}
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                push('/legal/privacy-policy');
              }}
              text={t('cookies.privacy_policy')}
              color="primary"
              fullWidth={isMobile}
            />
            {!isMobile && (
            <Diamond
              color={theme.palette.secondary.main}
              size={17}
            />
            )}

            <Button
              startIcon={<SettingsIcon />}
              className={classes.denyButton}
              size="small"
              variant="outlined"
              onClick={handleCustomize}
              text={t('cookies.custom')}
              color="primary"
              fullWidth={isMobile}
            />
            {!isMobile && (
            <Diamond
              color={theme.palette.secondary.main}
              size={17}
            />
            )}
            <Button
              startIcon={<ClearOutlinedIcon />}
              className={classes.denyButton}
              size="small"
              variant="contained"
              onClick={handleReject}
              text={t('cookies.reject')}
              color="primary"
              fullWidth={isMobile}
            />
            {!isMobile && (
            <Diamond
              color={theme.palette.secondary.main}
              size={17}
            />
            )}
            <Button
              startIcon={<CheckIcon />}
              variant="contained"
              className={classes.acceptAllButton}
              size="small"
              onClick={handleAllow}
              color="primary"
              text={t('cookies.accept')}
              fullWidth={isMobile}
            />
          </div>
        </div>
      </div>
      )}
    </>
  );
}
