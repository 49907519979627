/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarMessage } from 'notistack';

// A little hack...
// If we use the original OptionsObject, the defaultValue has a
// readonly array, which can't be assigned to the WritableDraft
// in Redux: "state.snackbars.push(snackbar);" would give us a
// TypeScript error.
interface SnackbarOptionsObject extends OptionsObject {
  defaultValue?: string | number | string[]
}

type Snackbar = {
  message: SnackbarMessage;
  key: number | string,
  dismissed: boolean;
  options: SnackbarOptionsObject
};

interface GlobalState {
  cookieConfigOpen: boolean
  isLoading: boolean
  error: string | null
  snackbars: Snackbar[]
}

const globalsInitialState: GlobalState = {
  cookieConfigOpen: false,
  isLoading: false,
  error: null,
  snackbars: [],
};

/* Async Thunks
   ========================
*/

// remove a snackbar
export const removeSnackbar = createAsyncThunk(
  'globals/removeSnackbar',
  async (
    {
      key,
    }
    : { key: number | string },
  ) => (
    { key }
  ),
);

// close a snackbar
export const closeSnackbar = createAsyncThunk(
  'globals/closeSnackbar',
  async (
    {
      dismissAll = false,
      key,
    }
    : { dismissAll?: boolean, key: number | string },
  ) => (
    { key, dismissAll }
  ),
);

// enqueue a snackbar
export const enqueueSnackbar = createAsyncThunk(
  'globals/enqueueSnackbar',
  async (
    {
      snackbar,
    }
    : { snackbar: Snackbar },
  ) => (
    { snackbar }
  ),
);

const globals = createSlice({
  name: 'globals',
  initialState: globalsInitialState,
  reducers: {
    toggleCookieConfiguration: (state) => {
      state.cookieConfigOpen = !state.cookieConfigOpen;
    },
    reset: () => globalsInitialState,
  },
  extraReducers: (builder) => {
    builder
      // remove a snackbar
      .addCase(removeSnackbar.fulfilled, (state, action) => {
        const { key } = action.payload;
        state.snackbars = state.snackbars.filter(
          (x) => x.key !== key,
        );
      })
      // close a snackbar
      .addCase(closeSnackbar.fulfilled, (state, action) => {
        const { key, dismissAll } = action.payload;
        state.snackbars.forEach((snackbar) => {
          if (dismissAll || snackbar.key === key) {
            snackbar.dismissed = true;
          }
        });
      })
      // enqueue a snackbarf
      .addCase(enqueueSnackbar.fulfilled, (state, action) => {
        const { snackbar } = action.payload;
        state.snackbars.push(snackbar);
      });
  },
});

export default globals.reducer;

export const { reset, toggleCookieConfiguration } = globals.actions;
