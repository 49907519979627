import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_DJANGO_HOST,
});

const api = (token?: string) => {
  instance.defaults.baseURL = process.env.NEXT_PUBLIC_DJANGO_HOST;
  if (token) {
    instance.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    instance.defaults.headers.common.Authorization = '';
  }
  return instance;
};

export default api;
