/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChangeObject } from 'typings/auth';
import authApi from './api';

interface UserObject {
  first_name: string
  last_name: string
  uses_social: {
    provider: undefined | 'Google' | 'Facebook'
  }
  avatar: string | null
  email: string
  color: string | null
  slug: string
  /* profile: {
    isVerified: boolean
    verificationReason: string | null
    bio: string
  } */
}

interface AuthState {
  isLoading: boolean
  isLoadingAndBlocking: boolean // full-screen loading
  restoreAttempted: boolean;
  error: string | null | undefined
  user: UserObject | null
  token: string | null
}

const authInitialState: AuthState = {
  isLoading: false,
  isLoadingAndBlocking: false,
  restoreAttempted: false,
  error: null,
  user: null,
  token: null,
};

/* Async Thunks
   ========================
*/

const api = authApi();

// sign in with email
export const signInEmail = createAsyncThunk(
  'auth/signInEmail',
  async (
    {
      email,
      password,
    }
    : { email: string, password: string },
  ) => api.signInEmail(email, password),
);

// checkEmail
export const checkEmail = createAsyncThunk(
  'auth/checkEmail',
  async (
    {
      email,
    }
    : { email: string },
  ) => api.checkEmail(email),
);

// register a user
export const register = createAsyncThunk(
  'auth/register',
  async ({
    email,
    password1,
    password2,
    firstName,
    lastName,
  }: {email:string,
    password1:string,
    password2:string,
    firstName:string,
    lastName:string}) => api.register(email, firstName, lastName, password1, password2),
);

// email confirmation after registering
export const confirmEmail = createAsyncThunk(
  'auth/confirmEmail',
  async ({
    key,
  }:{key:string}) => api.confirmEmail(key),
);

// restore a token
export const restoreToken = createAsyncThunk(
  'auth/restoreToken',
  async ({ token }: { token: string | null}) => api.restoreToken(token),
);

// get user data
export const getUserData = createAsyncThunk(
  'auth/getUserData',
  async (_, { getState }) => {
    const { auth } = getState();
    return api.getUserData(auth.token || '');
  },
);

// signout
export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { getState }) => {
    const { auth } = getState();
    return api.signOut(auth.token || '');
  },
);

// Google Login
export const signInGoogle = createAsyncThunk(
  'auth/signInGoogle',
  async ({ token, code }: {token:string, code:string}) => api.signInGoogle(token, code),
);

// Facebook Login
export const signInFacebook = createAsyncThunk(
  'auth/signInFacebook',
  async ({ token, code }: {token:string, code:string}) => api.signInFacebook(token, code),
);

// Password reset request
export const requestPasswordReset = createAsyncThunk(
  'auth/requestPasswordReset',
  async ({
    email,
  }:{email:string}) => api.requestPasswordReset(email),
);

export const confirmPasswordReset = createAsyncThunk(
  'auth/confirmPasswordReset',
  async ({
    new_password1, new_password2, uid, token,
  }:{new_password1:string,
    new_password2:string,
    uid:string,
    token:string}) => api.confirmPasswordReset(new_password1, new_password2, uid, token),
);

// ================ Settings =================

// Change Password from settings menu
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({
    new_password1,
    new_password2,
  }:{new_password1:string, new_password2:string}, { getState }) => {
    const { auth } = getState();
    return api.changePassword(new_password1, new_password2, auth.token || '');
  },
);

// Save User Information from settings menu

export const saveUserInfo = createAsyncThunk(
  'auth/saveUserInfo', async (changeObject :ChangeObject, { getState }) => {
    const { auth } = getState();
    return api.saveUserInfo(auth.token || '', changeObject);
  },
);

const auth = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // sign in with email
      .addCase(signInEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signInEmail.fulfilled, (state, action) => {
        state.token = action.payload.key;
        state.isLoading = false;
      })
      .addCase(signInEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // sign in with Google
      .addCase(signInGoogle.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signInGoogle.fulfilled, (state, action) => {
        state.token = action.payload.key;
        state.isLoading = false;
      })
      .addCase(signInGoogle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // sign in with Facebook
      .addCase(signInFacebook.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signInFacebook.fulfilled, (state, action) => {
        state.token = action.payload.key;
        state.isLoading = false;
      })
      .addCase(signInFacebook.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // get information of a user
      .addCase(getUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // restore a token
      .addCase(restoreToken.pending, (state) => {
        state.isLoadingAndBlocking = true;
      })
      .addCase(restoreToken.fulfilled, (state, action) => {
        const { token, user } = action.payload;
        state.token = token;
        state.user = user;
        state.isLoadingAndBlocking = false;
        state.restoreAttempted = true;
      })
      .addCase(restoreToken.rejected, (state) => {
        state.user = null;
        state.token = null;
        state.isLoadingAndBlocking = false;
        state.restoreAttempted = true;
        state.isLoadingAndBlocking = false;
      })
      // Sign Out
      .addCase(signOut.pending, (state) => {
        state.isLoadingAndBlocking = true;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.token = null;
        state.user = null;
        state.isLoadingAndBlocking = false;
        state.restoreAttempted = true;
      })
      // SaveUserInfo
      .addCase(saveUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      });
  },
});

export default auth.reducer;
