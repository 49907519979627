/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
// param reassign is safe because it's redux-toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import merge from 'lodash/merge';

import useCommunityApi from './api';

import communityInitialState from './initialState';

const api = useCommunityApi();

/* Async Thunks
   ========================
*/

// fetch list of "my boards"
export const fetchMyBoards = createAsyncThunk(
  'community/fetchMyBoards',
  async (_, { getState }) => {
    const { auth } = getState();
    return api.fetchMyBoards(auth.token || '');
  },
);

// fetch list of all boards
export const fetchAllBoards = createAsyncThunk(
  'community/fetchAllBoards',
  async (_, { getState }) => {
    const { auth } = getState();
    return api.fetchAllBoards(auth.token || '');
  },
);

// add a board to "my boards"
export const toggleBoardSubscription = createAsyncThunk(
  'community/toggleBoardSubscription',
  async ({
    boardId,
  }: { boardId: string }, { getState }) => {
    const { auth } = getState();
    return api.toggleBoardSubscription(auth.token || '', boardId);
  },
);

interface FetchPostListForBoardTypes {
  boardId: string
  // ordering,
  // timeframe,
  cursor?: string
}
// fetch the post list for a specific board
export const fetchPostListForBoard = createAsyncThunk(
  'community/fetchPostListForBoard',
  async ({
    boardId,
    // ordering,
    // timeframe,
    cursor,
  }: FetchPostListForBoardTypes, { getState }) => {
    const { auth } = getState();
    return api.fetchPostListForBoard(auth.token || '', boardId, cursor);
  },
);

// fetch the board info for a specific board
export const fetchInfoForBoard = createAsyncThunk(
  'community/fetchInfoForBoard',
  async ({
    boardId,
  }: { boardId: string }, { getState }) => {
    const { auth } = getState();
    return api.fetchInfoForBoard(auth.token || '', boardId);
  },
);

// create a new post
export const createPost = createAsyncThunk(
  'community/createPost',
  async ({
    title,
    board,
    external_url,
    content,
    tags,
  }: {
    title: string,
    content: string,
    board?: string,
    external_url?: string,
    tags?: string[]
  }, { getState }) => {
    const { auth } = getState();
    return api.createPost(auth.token || '', title, content, external_url, board, tags);
  },
);

// modify a post
export const modifyPost = createAsyncThunk(
  'community/modifyPost',
  async ({
    postId,
    content,
  }:{postId:string, content:string}, { getState }) => {
    const { auth } = getState();
    return api.modifyPost(auth.token || '', postId, content);
  },
);

// delete a post
export const deletePost = createAsyncThunk(
  'community/deletePost',
  async ({
    postId,
  }:{postId:string}, { getState }) => {
    const { auth } = getState();
    return api.deletePost(auth.token || '', postId);
  },
);

// toggle the "like" status of a post
export const toggleLikeForPost = createAsyncThunk(
  'community/toggleLikeForPost',
  async ({
    postId,
  }: { postId: string }, { getState }) => {
    const { auth } = getState();
    return api.toggleLikeForPost(auth.token || '', postId);
  },
);

// fetch a post
export const fetchPost = createAsyncThunk(
  'community/fetchPost',
  async ({
    postId,
  }: { postId: string }, { getState }) => {
    const { auth } = getState();
    return api.fetchPost(auth.token || '', postId);
  },
);

// Normalizr schema
const reply = new schema.Entity('reply', {}, { idAttribute: 'uuid' });
reply.define({
  replies: { serialized_replies: [reply] },
});
const replySchema = { results: [reply] };

// fetch a post's comment tree
export const fetchCommentTree = createAsyncThunk(
  'community/fetchCommentTree',
  async ({
    postId,
    cursor,
  }: { postId: string, cursor?: string }, { getState }) => {
    const { auth } = getState();
    return api.fetchCommentTree(auth.token || '', postId, cursor);
  },
);

// fetch comments from a base node (non-root)
export const fetchCommentsFromNode = createAsyncThunk(
  'community/fetchCommentsFromNode',
  async ({
    nodesToFetch,
    // we disable it because it's used in the reducer as an input arg
    parentNodeId,
  } : {
    parentNodeId: string,
    nodesToFetch: string[]
  }, { getState }) => {
    const { auth } = getState();
    return api.fetchCommentsFromNode(auth.token || '', nodesToFetch);
  },
);

// create a new comment
export const createComment = createAsyncThunk(
  'community/createComment',
  async ({
    content,
    parentPostId,
    parentCommentId,
  } : {
    content: string,
    parentPostId: string | undefined,
    parentCommentId: string | undefined,
  }, { getState }) => {
    const { auth } = getState();
    return api.createComment(auth.token || '', content, parentPostId, parentCommentId);
  },
);

// modify a comment
export const modifyComment = createAsyncThunk(
  'community/modifyComment',
  async ({
    commentId,
    content,
  }:{commentId:string, content:string}, { getState }) => {
    const { auth } = getState();
    return api.modifyComment(auth.token || '', commentId, content);
  },
);

// delete a comment
export const deleteComment = createAsyncThunk(
  'community/deleteComment',
  async ({
    commentId,
  }:{commentId:string}, { getState }) => {
    const { auth } = getState();
    return api.deleteComment(auth.token || '', commentId);
  },
);

// toggle the "like" status of a comment
export const toggleLikeForComment = createAsyncThunk(
  'community/toggleLikeForComment',
  async ({
    commentId,
  }: { commentId: string }, { getState }) => {
    const { auth } = getState();
    return api.toggleLikeForComment(auth.token || '', commentId);
  },
);

// // fetch list of users I follow
// export const fetchListOfFollowedUsers = createAsyncThunk(
//   'community/fetchListOfFollowedUsers',
//   async (_, { getState }) => {
//     const { auth } = getState();
//     return api.fetchListOfFollowedUsers(auth.token || '');
//   },
// );

// // fetch a user profile
// export const fetchUserProfile = createAsyncThunk(
//   'community/fetchUserProfile',
//   async ({
//     userId,
//   }, { getState }) => {
//     const { auth } = getState();
//     return api.fetchUserProfile(auth.token || '', userId);
//   },
// );

// // edit my profile
// export const editMyProfile = createAsyncThunk(
//   'community/editMyProfile',
//   async ({
//     patchObject,
//   }, { getState }) => {
//     const { auth } = getState();
//     return api.editMyProfile(auth.token || '', patchObject);
//   },
// );

// // fetch user's post activity
// export const fetchUserActivity = createAsyncThunk(
//   'community/fetchUserActivity',
//   async ({
//     userId,
//     cursor,
//   }, { getState }) => {
//     const { auth } = getState();
//     return api.fetchUserActivity(auth.token || '', userId, cursor);
//   },
// );

// report a post
// export const reportPost = createAsyncThunk(
//   'community/reportPost',
//   async () => api.reportPost(),
// );

const community = createSlice({
  name: 'community',
  initialState: communityInitialState,
  reducers: {
    reset: () => communityInitialState,
  },
  extraReducers: (builder) => {
    builder
      // fetch list of "my boards"
      .addCase(fetchMyBoards.pending, (state) => {
        state.myBoards.isLoading = true;
      })
      .addCase(fetchMyBoards.fulfilled, (state, action) => {
        const categories = action.payload;
        state.myBoards.isLoading = false;
        state.myBoards.data = categories;
      })
      .addCase(fetchMyBoards.rejected, (state, action) => {
        const { message } = action.error;
        state.myBoards.isLoading = false;
        state.myBoards.error = message || null;
      })
      // fetch list of all boards
      .addCase(fetchAllBoards.pending, (state) => {
        state.allBoards.isLoading = true;
      })
      .addCase(fetchAllBoards.fulfilled, (state, action) => {
        const categories = action.payload;
        state.allBoards.isLoading = false;
        state.allBoards.data = categories;
      })
      .addCase(fetchAllBoards.rejected, (state, action) => {
        const { message } = action.error;
        state.allBoards.isLoading = false;
        state.allBoards.error = message || null;
      })
      // detailed info for a specific board
      .addCase(fetchInfoForBoard.pending, (state) => {
        state.boardInfo.isLoading = true;
      })
      .addCase(fetchInfoForBoard.fulfilled, (state, action) => {
        state.boardInfo.isLoading = false;
        state.boardInfo.data = action.payload;
      })
      .addCase(fetchInfoForBoard.rejected, (state, action) => {
        const { message } = action.error;
        state.boardInfo.isLoading = false;
        state.boardInfo.error = message || null;
      })
      // fetch post list for specific board
      .addCase(fetchPostListForBoard.pending, (state, action) => {
        state.boardPostList.isLoading = true;
        if (!action.meta.arg.cursor) {
          state.boardPostList.data = communityInitialState.boardPostList.data;
        }
      })
      .addCase(fetchPostListForBoard.fulfilled, (state, action) => {
        // if we have a cursor that means we have to append
        if (action.meta.arg.cursor) {
          const { results, next } = action.payload;
          state.boardPostList.data.results = [...state.boardPostList.data.results, ...results];
          state.boardPostList.data.next = next;
        } else {
          state.boardPostList.data = action.payload;
        }
        state.boardPostList.isLoading = false;
      })
      .addCase(fetchPostListForBoard.rejected, (state, action) => {
        const { message } = action.error;
        state.boardPostList.isLoading = false;
        state.boardPostList.error = message || null;
      })
      // post detail view
      .addCase(fetchPost.pending, (state) => {
        state.post.post.isLoading = true;
        state.post.post.data = communityInitialState.post.post.data;
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        state.post.post.isLoading = false;
        state.post.post.data = action.payload;
      })
      .addCase(fetchPost.rejected, (state, action) => {
        const { message } = action.error;
        state.post.post.isLoading = false;
        state.post.post.error = message || null;
      })
      // Modify post
      .addCase(modifyPost.pending, (state) => {
        state.post.post.isLoading = true;
      })
      .addCase(modifyPost.fulfilled, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { updated_at, content, edit_counter } = action.payload;
        state.post.post.isLoading = false;
        const { postId } = action.meta.arg;
        if (state.post.post.data && state.post.post.data.slug === postId) {
          state.post.post.data.content = content;
          state.post.post.data.updated_at = updated_at;
          state.post.post.data.edit_counter = edit_counter;
        }
        const postToModify = state.boardPostList.data?.results.find((x) => x.slug === postId);
        if (postToModify) {
          postToModify.updated_at = updated_at;
          postToModify.content = content;
          postToModify.edit_counter = edit_counter;
        }
      })

    // Delete post
      .addCase(deletePost.pending, (state) => {
        state.post.post.isLoading = true;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.post.post.isLoading = false;
        const { postId } = action.meta.arg;
        if (state.post.post.data && state.post.post.data.slug === postId) {
          state.post.post.data.marked_as_deleted = true;
        }
        const postToDelete = state.boardPostList.data?.results.find((x) => x.slug === postId);
        if (postToDelete) {
          postToDelete.marked_as_deleted = true;
        }
      })
      // post detail view
      .addCase(fetchCommentTree.pending, (state, action) => {
        state.post.comments.isLoading = true;
        if (!action.meta.arg.cursor) {
          state.post.comments.results = communityInitialState.post.comments.results;
          state.post.comments.hashmap = communityInitialState.post.comments.hashmap;
        }
      })
      .addCase(fetchCommentTree.fulfilled, (state, action) => {
        const {
          next, results,
        } = action.payload;
        const normalizedResponse = normalize({ results }, replySchema);
        state.post.comments.next = next;
        if (action.meta.arg.cursor) {
          // we append
          state.post.comments.hashmap = [
            ...state.post.comments.hashmap,
            ...normalizedResponse.result.results,
          ];
          state.post.comments.results = merge({},
            state.post.comments.results, normalizedResponse.entities.reply || {});
        } else {
          state.post.comments.hashmap = normalizedResponse.result.results;
          state.post.comments.results = normalizedResponse.entities.reply || {};
        }
        state.post.comments.isLoading = false;
        state.post.comments.error = null;
      })
      .addCase(fetchCommentTree.rejected, (state, action) => {
        const { message } = action.error;
        state.post.comments.isLoading = false;
        state.post.comments.error = message || null;
      })
      // post detail view
      .addCase(fetchCommentsFromNode.fulfilled, (state, action) => {
        const normalizedResponse = normalize({ results: action.payload }, replySchema);

        // add responses to serialized replies
        const parentNode = state.post.comments.results[action.meta.arg.parentNodeId];

        parentNode.replies.serialized_replies = [
          ...parentNode.replies.serialized_replies,
          ...normalizedResponse.result.results,
        ];

        parentNode.replies.linked_replies = parentNode.replies?.linked_replies?.filter(
          (x: string) => (
            !normalizedResponse.result.results.includes(x)
          ),
        ) || [];
        parentNode.replies.remaining_replies_count -= normalizedResponse.result.results.length;

        state.post.comments.results = merge({},
          state.post.comments.results, normalizedResponse.entities.reply || {});
      })
      .addCase(createComment.fulfilled, (state, action) => {
        // if this is a level 0 reply, update the hashmap
        if (action.payload.parent_post) {
          state.post.comments.hashmap.unshift(action.payload.id);
        } else if (action.meta.arg.parentCommentId) {
          const itemToUpdate = state.post.comments.results[action.meta.arg.parentCommentId];
          itemToUpdate.replies.serialized_replies.unshift(action.payload);
        }
        state.post.comments.results = merge({},
          state.post.comments.results, { [action.payload.id]: action.payload } || {});
        // Update de number of replies
        if (state.post.post.data) {
          state.post.post.data.comment_counter += 1;
        }
      })
      // Modify comment
      .addCase(modifyComment.pending, (state) => {
        state.post.comments.isLoading = true;
      })
      .addCase(modifyComment.fulfilled, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { updated_at, content, edit_counter } = action.payload;
        state.post.comments.isLoading = false;
        const { commentId } = action.meta.arg;
        const commentToModify = state.post.comments.results[commentId];
        if (commentToModify) {
          commentToModify.updated_at = updated_at;
          commentToModify.content = content;
          commentToModify.edit_counter = edit_counter;
        }
      })
      .addCase(deleteComment.pending, (state) => {
        state.post.comments.isLoading = true;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        state.post.comments.isLoading = false;
        const { commentId } = action.meta.arg;
        const commentToDelete = state.post.comments.results[commentId];
        if (commentToDelete) {
          commentToDelete.marked_as_deleted = true;
        }
      })
      // toggle like for post
      .addCase(toggleLikeForPost.pending, (state, action) => {
        const postToToggle = state.boardPostList?.data?.results?.find(
          (x) => x.slug === action.meta.arg.postId,
        );
        if (postToToggle) {
          postToToggle.upvotes_count += postToToggle.has_user_upvoted ? -1 : 1;
          postToToggle.has_user_upvoted = !postToToggle.has_user_upvoted;
        }
        if (state.post.post.data?.slug === action.meta.arg.postId) {
          state.post.post.data.upvotes_count += state.post.post.data?.has_user_upvoted ? -1 : 1;
          state.post.post.data.has_user_upvoted = !state.post.post.data?.has_user_upvoted;
        }
      })
      .addCase(toggleLikeForPost.rejected, (state, action) => {
        const postToToggle = state.boardPostList?.data?.results?.find(
          (x) => x.slug === action.meta.arg.postId,
        );
        if (postToToggle) {
          postToToggle.upvotes_count += postToToggle.has_user_upvoted ? -1 : 1;
          postToToggle.has_user_upvoted = !postToToggle.has_user_upvoted;
        }
        if (state.post.post.data?.slug === action.meta.arg.postId) {
          state.post.post.data.upvotes_count += state.post.post.data?.has_user_upvoted ? -1 : 1;
          state.post.post.data.has_user_upvoted = !state.post.post.data?.has_user_upvoted;
        }
      })
      // toggle like for comment
      .addCase(toggleLikeForComment.pending, (state, action) => {
        const commentToToggle = state.post.comments.results[action.meta.arg.commentId];
        if (commentToToggle) {
          commentToToggle.upvotes_count += commentToToggle.has_user_upvoted ? -1 : 1;
          commentToToggle.has_user_upvoted = !commentToToggle.has_user_upvoted;
        }
      })
      .addCase(toggleLikeForComment.rejected, (state, action) => {
        const commentToToggle = state.post.comments.results[action.meta.arg.commentId];
        if (commentToToggle) {
          commentToToggle.upvotes_count += commentToToggle.has_user_upvoted ? -1 : 1;
          commentToToggle.has_user_upvoted = !commentToToggle.has_user_upvoted;
        }
      })
      // toggle board subscription
      .addCase(toggleBoardSubscription.fulfilled, (state, action) => {
        // TODO add board to myBoards
        // if boardInfo.data is for this board, update it too
        if (state.boardInfo.data?.slug === action.meta.arg.boardId) {
          state.boardInfo.data.is_user_subscribed = !state.boardInfo.data.is_user_subscribed;
        }
      });
  },
});

export default community.reducer;

export const { reset } = community.actions;
