import { CommunityState } from 'typings/community/state';

const communityInitialState: CommunityState = {
  myBoards: {
    isLoading: false,
    error: null,
    data: [],
  },
  allBoards: {
    isLoading: false,
    error: null,
    data: [],
  },
  boardInfo: {
    isLoading: false,
    error: null,
    data: null,
  },
  boardPostList: {
    isLoading: false,
    error: null,
    data: {
      next: null,
      previous: null,
      results: [],
    },
  },
  post: {
    post: {
      isLoading: false,
      error: null,
      data: null,
    },
    comments: {
      isLoading: false,
      error: null,
      next: null,
      hashmap: [],
      results: {},
      data: [],
    },
  },
};

export default communityInitialState;
