/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';

// local imports
import { toggleCookieConfiguration } from '@/features/globals/slice';
import Alert from '@material-ui/lab/Alert';
import Link from '@/components/links/Link';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonMargin: {
    width: '90%',
    padding: theme.spacing(1),
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  info: {
    margin: theme.spacing(1),
  },
}));

type Cookie = {
  consent: boolean,
  i18nkey: string,
  variable: string,
};

type CookieConfigurationDialogProps = {
  open: boolean;
};

const COOKIES = ['essential', 'analytics'] as const;

const CookieConfigurationDialog = ({ open }: CookieConfigurationDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleCookieConfiguration());
    if (!cookies.essential) {
      setCookie('essential', 1, { path: '/', secure: true, sameSite: 'lax' });
    }
  };

  const handleConsentChange = (cookie: string) => {
    if (!cookies[cookie]) {
      setCookie(cookie, 1, {
        path: '/', expires: new Date('2099-12-31'), secure: true, sameSite: 'lax',
      });
    } else {
      removeCookie(cookie, {
        path: '/', expires: new Date('2099-12-31'), secure: true, sameSite: 'lax',
      });
    }

    if (!cookies.essential) {
      setCookie('essential', 1, {
        path: '/', expires: new Date('2099-12-31'), secure: true, sameSite: 'lax',
      });
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="cookie-dialog-title" open={open}>
      <DialogTitle id="cookie-dialog-title">{t('cookies.configure_dialog_title')}</DialogTitle>
      <Alert
        className={classes.info}
        severity="info"
        action={(
          <Link
            href="https://www.wixdom.io/privacy"
          >
            {t('cookies.configure_dialog_info_text_action')}
          </Link>
      )}
      >
        {t('cookies.configure_dialog_info_text')}
      </Alert>
      <List>
        {COOKIES.map((cookie) => (
          <ListItem key={cookie}>
            <ListItemAvatar className={classes.center}>
              <Switch
                checked={cookies[cookie]}
                onChange={() => handleConsentChange(cookie)}
                name="checked"
                disabled={cookie === 'essential'}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={t(`cookies.${cookie}.title` as const)}
              secondary={t(`cookies.${cookie}.description` as const)}
            />
          </ListItem>
        ))}
      </List>
      <div className={classes.buttonMargin}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => handleClose()}
        >
          {t('cookies.save_button')}
        </Button>
      </div>

    </Dialog>
  );
};

export default CookieConfigurationDialog;
